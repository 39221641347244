/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_appsync_graphqlEndpoint": "https://qdwpuf5o4raz5d6w3mqxsxcil4.appsync-api.ap-southeast-2.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-rzrrdikmrnbkfbjtvy43wbpkau",
    "aws_cognito_identity_pool_id": "ap-southeast-2:1f4a46d0-edca-44a6-a2b2-c67a7d3bffea",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_dYYfz4dwB",
    "aws_user_pools_web_client_id": "4n7ncjfnf7jilb0n901u8e3qmh",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "BIRTHDATE",
        "EMAIL",
        "NAME",
        "PHONE_NUMBER"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "PHONE_NUMBER"
    ],
    "aws_user_files_s3_bucket": "flairpatientapp2297bc41feb94add91ccd66c7118f52b144301-staging",
    "aws_user_files_s3_bucket_region": "ap-southeast-2"
};


export default awsmobile;
