import React, { useState, useEffect, useCallback } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, CircularProgress, Grid, MenuItem, Select, ThemeProvider, Typography, TextField } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { theme } from '../theme';

import { Amplify } from 'aws-amplify';
import { generateClient } from 'aws-amplify/api';
import { getCurrentUser } from 'aws-amplify/auth';
import config from '../amplifyconfiguration.json';

import { getPatient } from "../graphql/queries";
import { updateGoal } from "../graphql/mutations";

Amplify.configure(config);
const client = generateClient({ authMode: 'apiKey' });

export default function CheckInForm() {
    const [ user, setUser ] = useState("");
    const [ patientData, setPatientData ] = useState("");
  
    useEffect(() => { //authUser
      async function authUser() {
        try {
          const response = await getCurrentUser();
          // console.log("Auth User:", response.userId);
          setUser(response.userId);
        } catch (err) {
          console.log(err);
        }
      }
      authUser();
    }, []);
  
    const getPatientData = useCallback(async () => {
        try {
          const response = await client.graphql({ query: getPatient, variables: { id: user } });
          setPatientData(response.data.getPatient);
          // console.log("Patient Data:", response.data.getPatient);
        } catch (error) {
          console.error('Error getting patient data:', error);
        }
      }, [user, setPatientData]);
  
    useEffect(() => { //getPatientData
      if (user) {
        getPatientData();
      }
    }, [user, getPatientData]);

    function FormContent() {
        const isSMScreen = useMediaQuery(theme.breakpoints.down('sm'));
        const { goalId } = useParams();
        const navigate = useNavigate();

        const patientGoalsList = () => { //least to most recent
            const sortedGoals = patientData.goals.items.sort((a, b) => 
              new Date(a.endTime) - new Date(b.endTime)
            );
            return sortedGoals;
        }
        const goalsList = patientGoalsList();

        const goalIndex = goalsList.findIndex(goal => goal.id === goalId);
        const hasPrevGoal = goalIndex > 0 && goalsList[goalIndex - 1].q1_goal;
        const prevGoal = hasPrevGoal ? goalsList[goalIndex - 1].q1_goal : null;

        const [currentPage, setCurrentPage] = useState(0);
        const [formData, setFormData] = useState({ 
            q0_prevAchieved: '',
            q1_goal: '',
            q2_positive: '',
            q3_improve: '',
            q4_discuss: '',
        });
        // console.log(formData);

        useEffect(() => {
            if (goalsList && goalsList.length > 0) {
              const matchingGoal = goalsList.find(goal => goal.id === goalId);
              if (matchingGoal) {
                setFormData({
                  q0_prevAchieved: typeof matchingGoal.q0_prevAchieved === 'boolean' ? matchingGoal.q0_prevAchieved : true,
                  q1_goal: matchingGoal.q1_goal || '',
                  q2_positive: matchingGoal.q2_positive || '',
                  q3_improve: matchingGoal.q3_improve || '',
                  q4_discuss: matchingGoal.q4_discuss || ''
                });
              }
            }
        }, [goalsList, goalId]);

        let questions = [
            { name: 'q1_goal', text: 'What is your health goal for this week?', format: "short_text" },
            { name: 'q2_positive', text: 'What progress did you make towards your goals last week?', format: "long_text" },
            { name: 'q3_improve', text: 'Did anything make it difficult to focus on your health goal last week?', format: "long_text" },
            { name: 'q4_discuss', text: 'Do you have any questions for your health coach or dietitian?', format: "long_text" }
        ];

        if (hasPrevGoal) {
        questions.unshift({ 
            name: 'q0_prevAchieved', 
            text: `Last week, you set yourself this goal: "${prevGoal}". Did you achieve this health goal?`, 
            format: "yes_no" 
        });
        }

        const totalQuestions = questions.length;

        const updateGoalInDB = async (updatedFormData) => {
            try {
              const response = await client.graphql({ 
                query: updateGoal, 
                variables: { 
                  input: {
                    id: goalId,
                    q0_prevAchieved: updatedFormData.q0_prevAchieved,
                    q1_goal: updatedFormData.q1_goal,
                    q2_positive: updatedFormData.q2_positive,
                    q3_improve: updatedFormData.q3_improve,
                    q4_discuss: updatedFormData.q4_discuss,
                    patientId: patientData.id
                  }
                }
              });
              // console.log('Goal updated successfully.', response.data.updateGoal);
            } catch (err) {
              console.error('Error updating goal:', err);
            }
        };

        const handleNext = () => {
            if (currentPage < totalQuestions - 1) {
                const updatedFormData = {
                    ...formData,
                    [questions[currentPage].name]: formData[questions[currentPage].name] || ''
                };
                setFormData(updatedFormData);
                updateGoalInDB(updatedFormData);
                setCurrentPage(currentPage + 1);
            }
            // console.log(formData);
        };
        
        const handlePrev = () => {
            if (currentPage > 0) {
            updateGoalInDB(formData);
            setCurrentPage(currentPage - 1);
            }
            // console.log(formData);
        };

        const handleBack = async () => {
            await updateGoalInDB(formData);
            navigate('/goals');
        };

        const handleInputChange = (e) => {
            const { name, value } = e.target;
            setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
            }));
        };

        const updatePreviousGoal = async(currentGoalId, updateAchieved) => {
            const goalIndex = goalsList.findIndex(goal => goal.id === currentGoalId);
            if (goalIndex > 0) {
                const previousGoalId = goalsList[goalIndex - 1].id;
                try {
                    const response = await client.graphql({ 
                        query: updateGoal, 
                        variables: { 
                        input: {
                            id: previousGoalId,
                            achieved: updateAchieved,
                        }
                        }
                    });
                    // console.log('Previous goal updated successfully.', response.data.updateGoal);
                } catch (err) {
                    console.error('Error updating previous goal:', err);
                }
            }
        };

        const handleSubmit = async () => {
            // console.log("Submission:", formData);
            try {
                const response = await client.graphql({ 
                    query: updateGoal, 
                    variables: { 
                    input: {
                        id: goalId,
                        q0_prevAchieved: formData.q0_prevAchieved,
                        q1_goal: formData.q1_goal,
                        q2_positive: formData.q2_positive,
                        q3_improve: formData.q3_improve,
                        q4_discuss: formData.q4_discuss,
                        patientId: patientData.id
                    }
                    }
                });
                // console.log('Goal added successfully.', response.data.updateGoal);
                updatePreviousGoal(goalId, formData.q0_prevAchieved)
                navigate(`/goals`);
            } catch (err) {
                console.error('Error submitting goal:', err);
            }
        };

        const handleQuestionFormat = (format) => {
            switch (format) {
            case 'yes_no':
                return <Select name={questions[currentPage].name}
                        value={formData[questions[currentPage].name]}
                        onChange={handleInputChange} 
                        style={{ margin: '20px', width: '80%', overflowWrap: 'break-word' }}>
                            <MenuItem value={true}>Yes, I achieved it!</MenuItem>
                            <MenuItem value={false}>No, I didn't achieve it.</MenuItem>
                        </Select>;
            case 'short_text':
                return <TextField label={<span style={{ display: 'block', width: '100%', whiteSpace: 'pre-wrap' }}>
                        {questions[currentPage].text} </span>}
                        name={questions[currentPage].name} 
                        value={formData[questions[currentPage].name]}
                        onChange={handleInputChange} 
                        style={{ margin: '20px', width: '80%', overflowWrap: 'break-word' }} />;
            case 'long_text':
                return <TextField label={<span style={{ display: 'block', width: '100%', whiteSpace: 'pre-wrap' }}>
                        {questions[currentPage].text} </span>}
                        name={questions[currentPage].name} 
                        value={formData[questions[currentPage].name]}
                        onChange={handleInputChange} 
                        style={{ margin: '20px', width: '80%', overflowWrap: 'break-word' }} 
                        multiline rows={4} />;
            default:
                return <TextField label={<span style={{ display: 'block', width: '100%', whiteSpace: 'pre-wrap' }}>
                        {questions[currentPage].text} </span>}
                        name={questions[currentPage].name} 
                        value={formData[questions[currentPage].name]}
                        onChange={handleInputChange} 
                        style={{ margin: '20px', width: '80%', overflowWrap: 'break-word' }} 
                        multiline rows={4} />;
            }
        };

        return (
            <div>

            <div style={{ margin: '20px' }}>
                <Link to="/goals" className="link">
                <Button variant="contained" color="primary" size="small" style={{color:'white'}} onClick={handleBack}> Back </Button>
                </Link>
            </div>
            
            <form onSubmit={handleSubmit}>
            <Typography variant={isSMScreen ? 'h6' : 'h6'} style={{ margin: '20px', marginTop: '25px', marginBottom: '0px' }}>
                <b>Question {currentPage + 1}: {questions[currentPage].text} </b> 
            </Typography>

            {handleQuestionFormat(questions[currentPage].format)}

            <div style={{ margin: '20px' }}>
            {currentPage > 0 && (
                <Button variant={'contained'} onClick={handlePrev} style={{color:'white'}}>Previous</Button>
            )}

            {currentPage < totalQuestions - 1 ? (
                <Button variant={'contained'} onClick={handleNext} style={{color:'white'}}>Next</Button>
            ) : (
                <Button variant={'contained'} onClick={handleSubmit} style={{color:'white'}}>Submit</Button>
            )}
            </div>
            </form>
            </div>
        );
    }

    return (
        <ThemeProvider theme={theme}>
        <Grid container>
            <Grid item xs={12}>
                {/* <Navbar /> */}
            </Grid>
            <div className="App">
            <Grid item xs={12} md={12}> 
                {patientData ? (
                    <>
                    <FormContent /> 
                    </>
                ) : (
                    <CircularProgress />
                )}
            </Grid>
            <Typography variant="body2" style={{ textAlign: 'left', paddingBottom: '20px' }}> 
                Powered by <a href="https://www.flairhealth.com" target="_blank" rel="noopener noreferrer"> Flair Health </a>
            </Typography>
            </div>
        </Grid>
        </ThemeProvider>
    );
}