import React from 'react';
import { Typography } from '@mui/material';

export default function IntermittentFasting() {
  return (
    <div>
        <Typography variant="h4" gutterBottom color="primary.main">
        <b>Intermittent Fasting: A Beginner’s Guide</b>
        </Typography>

        <Typography variant="h5" gutterBottom color="primary.main">
        What is Intermittent Fasting?
        </Typography>

        <Typography paragraph>
        Intermittent fasting, also known as time-restricted eating, is an eating plan that involves switching between periods of fasting and eating. It doesn’t specify *which* foods you should eat, but rather *when* you should eat them. 
        </Typography>

        <Typography variant="h5" gutterBottom color="primary.main">
        The Benefits of Intermittent Fasting
        </Typography>
        <Typography paragraph>
        Most people try intermittent fasting for its weight loss benefits, it can also improve insulin resistance with patients in diabetes, pre-diabetes, and other conditions. These benefits can help you lower your risk of obesity-related diseases, including diabetes, arthritis, asthma, stroke, heart disease, Alzheimer’s disease, and some cancers. 
        </Typography>
        <Typography paragraph>
        Intermittent fasting helps you both eat fewer calories and burn more calories. When we eat frequently, this leads to continuous insulin production. This can cause cells to resist insulin and the body has to produce more as a result. In contrast, when we fast for extended periods, our blood sugar and insulin levels drop significantly. These lower insulin levels make it easier for cells to respond to insulin, allowing our body to burn fat as energy. 
        </Typography>
        <Typography paragraph>      
        Intermittent fasting allows insulin levels to go down far enough and for long enough to burn off fat. Not only does this help burn fat, it can also lower your risk of disease, particularly diabetes, pre-diabetes, and high blood pressure. When intermittent fasting, several changes happen in your body at a cellular and molecular level: 
        </Typography>
        <Typography paragraph>
        <b>Insulin Resistance: </b> Intermittent fasting improves insulin sensitivity by lowering insulin resistance, which is the body's ability to respond effectively to insulin. <a href='https://www.sciencedirect.com/science/article/abs/pii/S193152441400200X'>Studies</a> have shown that intermittent fasting can reduce blood sugar levels by 3-6% and and fasting insulin levels by 20-31%.
        </Typography>
        <Typography paragraph>
        <b>Increased Fat Burning: </b> During fasting periods, when the body is not receiving calories from food, it relies more on stored energy sources, primarily fat, for fuel. This increased utilization of stored fat can lead to weight loss and a reduction in body fat percentage, contributing to improved metabolic health.
        </Typography>
        <Typography paragraph>
        <b>Enhanced Hormone Regulation: </b> Intermittent fasting can influence the secretion of various hormones, including Human Growth Hormone (HGH). HGH levels tend to rise significantly during fasting, which can promote fat loss and muscle gain. Additionally, fasting can increase the release of norepinephrine/noradrenaline, a hormone that stimulates fat breakdown and energy expenditure.
        </Typography>
        <Typography paragraph>
        <b>Cellular Repair and Autophagy: </b>Fasting triggers processes like autophagy, where cells remove old and dysfunctional components, including damaged mitochondria. This cellular clean-up can enhance the efficiency of energy production and overall metabolic function.
        </Typography>
        <Typography paragraph>
        <b>Increased Resting Metabolic Rate: </b> Some studies suggest that intermittent fasting can temporarily boost resting metabolic rate, the number of calories your body burns at rest. This effect may be due to increased energy expenditure during fasting periods.
        </Typography>
        <Typography paragraph>
        <b>Improved Fat Mobilization: </b> Fasting can make stored body fat more accessible for energy use. As insulin levels drop, fat cells can release stored triglycerides into the bloodstream for utilization, contributing to fat loss.
        </Typography>
        <Typography paragraph>
        <b>Caloric Restriction: </b> Intermittent fasting typically leads to a reduction in overall calorie intake because of shorter eating windows. Consistently consuming fewer calories can help with weight management and metabolic health.
        </Typography>
        <Typography paragraph>
        <b>Reduced Inflammation: </b> Some studies suggest that intermittent fasting can reduce markers of inflammation, which is often associated with metabolic syndrome and insulin resistance and drives many other chronic diseases.
        </Typography>
        <Typography paragraph>
        A 2014 review study found that this eating pattern can cause <a href='https://www.sciencedirect.com/science/article/abs/pii/S193152441400200X'>3–8% weight loss over 3–24 weeks</a>, which is a significant amount, compared to most weight loss studies. According to the same study, people also lost 4–7% of their waist circumference from intermittent fasting. However, keep in mind that many of the studies about intermittent fasting were small, short-term, or conducted in animals. Many questions have yet to be answered in higher quality human studies.
        </Typography>

        <Typography variant="h5" gutterBottom color="primary.main">
        How to Practice Intermittent Fasting
        </Typography>
        <Typography paragraph>
        There are several different methods of intermittent fasting, all of which involve splitting the day or week into eating and fasting periods. During the fasting periods, you eat either very little or nothing at all. You can drink water, coffee, or tea during these periods.
        </Typography>
        <Typography paragraph>
        There are many types of intermittent fasting. It may take some experimentation to determine what method will work best for you:
        </Typography>
        <Typography paragraph>
        <b>The 16/8 Method: </b> In this method, you eat normally, but you’ll restrict your daily eating period to 8 hours in the day and fast for the remaining 16 hours. For example, you can skip breakfast, eat lunch around noon, and complete dinner by 8 p.m.
        </Typography>
        <Typography paragraph>
        <b>The 5:2 Diet: </b> In this method, you consume only 500–600 calories on two nonconsecutive days of the week, but eat normally the other 5 days.
        </Typography>
        <Typography paragraph>
        <b>Eat-Stop-Eat:</b> This involves fasting for 24 hours, once or twice a week. For example, you can skip eating from dinner one day until dinner the next day.
        </Typography>
        <Typography paragraph>
        <b>Alternate-Day Fasting:</b> This involves eating a normal diet one day and completely fasting or having one small meal (less than 500 calories) the next day.
        </Typography>
        <Typography paragraph>
        Many people consider the 16/8 method to be the simplest and most sustainable way of intermittent fasting — you might want to try this practice first. If this feels good, you can experiment with the different approaches and find something that feels best.
        </Typography>
        <Typography paragraph>
        While intermittent fasting, it is important to eat in moderation and consume high-quality foods, or the benefits of fasting may not be realized. In addition, all weight loss methods can cause you to lose muscle mass, which is why it is important to lift weights and keep your protein intake high. 
        </Typography>
        <Typography paragraph>
        Another approach is to simply skip meals from time to time when you’re not hungry. There is no need to follow a structured intermittent fasting plan to derive at least some of the benefits.
        </Typography>

        <Typography variant="h5" gutterBottom color="primary.main">
        Side Effects and Risks of Intermittent Fasting
        </Typography>
        <Typography paragraph>
        Intermittent fasting may not be for everyone. Hunger is often the main side effect of intermittent fasting, as well as mood swings, fatigue, and weakness. This may only be temporary while your body adapts to the new meal plan.
        </Typography>
        <Typography paragraph>
        If you have a medical condition, you should consult with your doctor before trying intermittent fasting, especially for children under 18, women who are pregnant or breastfeeding, individuals who are underweight or with a history of eating disorders, individuals with diabetes or other hormone/insulin resistance conditions, and individuals with low blood pressure.
        There is also some conflicting evidence about intermittent fasting in women. Women should ease into the practice and stop immediately if they have any problems (such as loss of menstruation).
        </Typography>
        <Typography paragraph>
        Intermittent fasting is one of many strategies you can use to improve your metabolic health, and it can be a very powerful tool to lose weight and improve your health. Eating healthy food, exercising, and sleeping are also important to maintain good health. 
        Certain meal plans and fasting methods may work better for some people. If you’re interested in intermittent fasting for your health, please consult your dietitian or doctor to determine how to make healthy, sustainable changes to your eating plan.
        </Typography>
        <Typography paragraph>
        At the end of the day, there is no one-size-fits-all solution when it comes to nutrition – the best strategies are ones that are sustainable for you and make you feel good.
        </Typography>
    </div>
  );
}