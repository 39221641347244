import React from 'react';
import { Paper, ThemeProvider, Typography, Grid } from '@mui/material';
import { theme } from '../theme';

export default function NotAuth404() {
  return (
    <ThemeProvider theme={theme}>
      <Grid container>
        <div className="App">
          <Grid item xs={12}>
              <Paper elevation={0} style={{ margin: '20px', paddingBottom: '2px', minHeight: '50px', marginTop: '10px', minWidth: '340px' }}>
                <Typography variant="h1" color="primary.main"><b>404 - Not Found</b> </Typography>
                <Typography variant="h4"> Sorry, the page you are looking for does not exist. </Typography>
              </Paper>
          </Grid>
          <Typography variant="body2" style={{ textAlign: 'left', paddingBottom: '20px' }}> 
            Powered by <a href="https://www.flairhealth.com" target="_blank" rel="noopener noreferrer"> Flair Health </a>
          </Typography>
        </div>
      </Grid>
    </ThemeProvider>
  );
};