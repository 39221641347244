import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Box, Button, CircularProgress, Grid, Modal, Paper, ThemeProvider, Typography } from '@mui/material';
import moment from 'moment';
import useMediaQuery from '@mui/material/useMediaQuery';
import { theme } from '../theme';

import { Amplify } from 'aws-amplify';
import { generateClient } from 'aws-amplify/api';
import { getCurrentUser } from 'aws-amplify/auth';
import { downloadData } from 'aws-amplify/storage';
import config from '../amplifyconfiguration.json';

import { getPatient } from "../graphql/queries";

Amplify.configure(config);
const client = generateClient({ authMode: 'apiKey' });

const modalStyle = {
  position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, 
  bgcolor: 'background.paper', boxShadow: 24, pt: 2, px: 4, pb: 3,
};

export default function AppointmentPage() {
  const [ user, setUser ] = useState("");
  const [ patientData, setPatientData ] = useState("");

  useEffect(() => { //authUser
    async function authUser() {
      try {
        const response = await getCurrentUser();
        // console.log("Auth User:", response.userId);
        setUser(response.userId);
      } catch (err) {
        console.log(err);
      }
    }
    authUser();
  }, []);

  const getPatientData = useCallback(async () => {
    try {
      const response = await client.graphql({ query: getPatient, variables: { id: user } });
      setPatientData(response.data.getPatient);
      // console.log("Patient Data:", response.data.getPatient);
    } catch (error) {
      console.error('Error getting patient data:', error);
    }
  }, [user, setPatientData]);

  useEffect(() => { //getPatientData
    if (user) {
      getPatientData();
    }
  }, [user, getPatientData]);

  function UpcomingAppointment() {
    const isSMScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const boxSize = '90px';
    const [ clinicianImages, setClinicianImages ] = useState([]);
    
    const futureAppointments = () => {
      const currentDateTime = new Date().toISOString();
      const filteredAppointments = patientData.appointments.items.filter(appointment => 
        new Date(appointment.endTime) >= new Date(currentDateTime)
      );
      const sortedAppointments = filteredAppointments.sort((a, b) => 
        new Date(a.endTime) - new Date(b.endTime)
      );
      return sortedAppointments;
    }
    const appointmentsInFuture = futureAppointments();

    const currentAppointmentFlag = (appt) => {
      const currentDateTime = new Date().toISOString();
      if (new Date(appt.startTime) <= new Date(currentDateTime)) {
            return  <Box sx={{ position: 'relative', display: 'flex', height: '30px', overflow: 'hidden', alignItems: 'center', justifyContent: 'left' }}>
              <Typography variant='body2' color="red" style={{ textAlign: 'left', marginBottom: '10px' }}> <b>Happening Now!</b> </Typography>
              </Box>
      }
    };

    useEffect(() => { //fetchImages
      const fetchImages = async () => {
        for (const appointment of appointmentsInFuture) {
          const imagePath = appointment.clinician.imagePath;
          if (imagePath && !clinicianImages[imagePath]) {
            try {
              const { body, eTag } = await downloadData({
                key: `${appointment.clinician.imagePath}.jpeg`,
                options: { accessLevel: 'guest'}
              }).result;
              const blob = new Blob([body], { type: 'image/jpeg' });
              const url = URL.createObjectURL(blob);
              setClinicianImages(prevUrls => ({ ...prevUrls, [imagePath]: url }));
            } catch (error) {
              const { body, eTag } = await downloadData({
                key: `empty_profile.jpeg`,
                options: { accessLevel: 'guest'}
              }).result;
              const blob = new Blob([body], { type: 'image/jpeg' });
              const url = URL.createObjectURL(blob);
              setClinicianImages(prevUrls => ({ ...prevUrls, [imagePath]: url }));
              console.error('Error downloading image:', error);
            }
          }
        }
      };
      fetchImages();
      console.log(clinicianImages);
    }, [appointmentsInFuture]);

    return (
      <div>
        <Typography variant={isSMScreen ? 'h6' : 'h5'} style={{margin: '20px', marginTop: '25px', marginBottom: '0px'}}> <b>Upcoming Appointments</b> </Typography>
        {appointmentsInFuture.length > 0 ? (
          appointmentsInFuture.map(appointment => (
          <Paper elevation={2} key={appointment.id} style={{ margin: '20px', padding: '20px', minHeight: '75px', marginTop: '10px', flex: 1 }}>
            {currentAppointmentFlag(appointment)}
            <Grid container spacing={2} alignItems="center" style={{ display: 'flex', flexGrow: 1 }}>
            <Grid item xs={8} md={8}>
              <Typography variant={isSMScreen ? 'body1' : 'h6'} style={{ textAlign: 'left', margin: '0' }}> <b>Appointment with {appointment.clinician.firstName} {appointment.clinician.lastName}</b> </Typography>
              <Typography variant="body2" color="primary.main" style={{ textAlign: 'left', margin: '0', paddingBottom: '5px' }}> <b>{moment(appointment.startTime).local().format('MMM DD, YYYY [at] h:mm A')} ({moment(appointment.endTime).diff(moment(appointment.startTime), 'minutes')} mins)</b> </Typography>
            </Grid>
            <Grid item xs={4} md={4}>
              <Box sx={{ position: 'relative', display: 'flex', width: boxSize, height: boxSize, 
                        borderRadius: '50%', overflow: 'hidden', alignItems: 'center', justifyContent: 'center' }}>
                <img src={clinicianImages[appointment.clinician.imagePath]} alt="Clinician" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
              </Box>
            </Grid>
            </Grid>
            <Typography variant="body1" style={{ textAlign: 'left', marginTop: '10px' }}> <b>Meeting Information</b> </Typography>
            {appointment.appointmentLocation === "Zoom" ? (
            <>
              <Typography variant="body2" style={{ textAlign: 'left', margin: '0', marginBottom: '5px' }}> Zoom {appointment.zoomInfo} </Typography>
              <Link to={appointment.zoomLink} className="link">
                <Button variant="contained" color="primary" size="small" style={{ color: 'white', margin: '0' }}> Join Zoom </Button>
              </Link>
              </>
            ) : (
              <Typography variant="body2" style={{ textAlign: 'left', margin: '0' }}>
                We will call you at {appointment.phoneNumber}.
              </Typography>
            )}
            <Typography variant="body2" style={{ textAlign: 'left', marginTop: '10px' }}> <i>If you need to reschedule, please text your care team at (833)658-5015.</i> </Typography>
          </Paper>
          ))
        ) : (
          <Paper elevation={2} style={{ margin: '20px', padding: '10px', textAlign: 'center' }}>
            <Typography variant="body1"> No Upcoming Appointments </Typography>
            <Typography variant="body2"> Text our helpline at (833)658-5015 to schedule an appointment.</Typography>
          </Paper>
        )}
      </div>
    )
  }
  
  function PastAppointments() {
    const isSMScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const boxSize = '75px';

    const pastAppointments = () => {
      const currentDateTime = new Date().toISOString();
      const filteredAppointments = patientData.appointments.items.filter(appointment => 
        new Date(appointment.endTime) < new Date(currentDateTime)
      );
      const sortedAppointments = filteredAppointments.sort((a, b) => 
        new Date(b.endTime) - new Date(a.endTime)
      );
      return sortedAppointments;
    }
    const appointmentsInPast = pastAppointments();

    const [selectedAppointmentId, setSelectedAppointmentId] = useState(null);
    const handleNotesOpen = (appointmentId) => {
      setSelectedAppointmentId(appointmentId);
    };
    const handleNotesClose = () => {
      setSelectedAppointmentId(null);
    };

    return (
      <div>
        <Typography variant={isSMScreen ? 'h6' : 'h5'} style={{margin: '20px', marginTop: '15px', marginBottom: '0px'}}> <b>Past Appointments</b> </Typography>
        {appointmentsInPast.length > 0 ? (
          appointmentsInPast.map(appointment => (
            <Paper elevation={2} key={appointment.id} style={{ margin: '20px', padding: '20px', minHeight: '75px', marginTop: '10px' }}>
              <Grid container spacing={2} alignItems="center">
              <Grid item xs={8} md={8}>
                  <Typography variant={isSMScreen ? 'body1' : 'h6'} style={{ textAlign: 'left', margin: '0' }}> <b>{appointment.appointmentType} Appointment</b> </Typography>
                  <Typography variant="body2" color="primary.main" style={{ textAlign: 'left', margin: '0' }}> <b>{moment(appointment.startTime).local().format('MMM DD, YYYY')}</b> </Typography>
              </Grid>
              <Grid item xs={4} md={4}>
                <Box sx={{ position: 'relative', display: 'inline-flex', width: boxSize, height: boxSize,
                          overflow: 'hidden', alignItems: 'center', justifyContent: 'center' }}>
                  <Button variant="contained" color="primary" size="small" onClick={() => handleNotesOpen(appointment.id)} style={{color:'white'}}> Notes </Button>
                  {selectedAppointmentId === appointment.id && (
                  <Modal open={selectedAppointmentId === appointment.id} onClose={handleNotesClose}>
                    <Box sx={modalStyle}>
                      <Typography variant="h6" color="primary.main" component="h2"> <b>Appointment Notes</b> </Typography>
                      {appointment.notes ? ( 
                        <Typography sx={{ mt: 2 }}> {appointment.notes} </Typography>
                      ) : ( 
                        <Typography sx={{ mt: 2 }}> <i>Appointment notes haven't been added yet – check back soon!</i> </Typography>
                      )}
                    </Box>
                  </Modal>
                  )}
                </Box>
              </Grid>
              </Grid>
            </Paper>
          ))
        ) : (
          <Paper elevation={2} style={{ margin: '20px', padding: '10px', textAlign: 'center' }}>
            <Typography variant="body1">No Past Appointments</Typography>
          </Paper>
        )}
      </div>
    )
  }

  return (
    <ThemeProvider theme={theme}>
      <Grid container>
        <Grid item xs={12}>
          {/* <Navbar /> */}
        </Grid>
        <div className="App"> 
          <Grid item xs={12}>
            {patientData ? (
              <>
              <UpcomingAppointment />
              {/* <CareTeam /> */}
              <PastAppointments />
              </>
            ) : (
              <CircularProgress />
            )}
          </Grid>
          <Grid item xs={12}>
          <Typography variant="body2" style={{ textAlign: 'left', paddingBottom: '20px' }}> 
            Powered by <a href="https://www.flairhealth.com" target="_blank" rel="noopener noreferrer"> Flair Health </a>
          </Typography>
          </Grid>
        </div>
      </Grid>
    </ThemeProvider>
  );
}