import React, { useEffect, useState, useRef } from 'react';
import { Button, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Paper, ThemeProvider, Typography, Select } from '@mui/material';
import { theme } from '../theme';

import { Amplify } from 'aws-amplify';
import { uploadData } from 'aws-amplify/storage';
import { generateClient } from 'aws-amplify/api';
import { getCurrentUser } from 'aws-amplify/auth';
import config from '../amplifyconfiguration.json';

import { getPatient, listPatients, 
        getAppointment, listAppointments,
        getGoal, listGoals,
        getClinician, listClinicians } from "../graphql/queries";
import { createPatient, updatePatient, deletePatient,
        createAppointment, updateAppointment, deleteAppointment,
        createGoal, updateGoal, deleteGoal, 
        createClinician, updateClinician, deleteClinician } from "../graphql/mutations";

Amplify.configure(config);
const client = generateClient({ authMode: 'apiKey' });

export default function Admin() {
    const [patient, setPatient] = useState(null);
    const [patientsList, setPatientsList] = useState([]);
    const [patientData, setPatientData] = useState("");
    const hasSetInitialPatient = useRef(false);
    const [uploadStatus, setUploadStatus] = useState('');

    const [file, setFile] = useState(null);
    const [filename, setFilename] = useState('');

    // Get current patient's data
    async function getCurrentPatientData() {
        try {
        const response = await client.graphql({ 
            query: getPatient, 
            variables: { id: patient } 
        });
        console.log('Patient data queried successfully.', response.data.getPatient);
        setPatientData(response.data.getPatient);
        return response.data.getPatient;
        } catch (error) {
        console.error('Error getting patient data:', error);
        }
    }

    // Get list of all patients
    async function getAllPatients() {
        try {
        const response = await client.graphql({ query: listPatients });
        console.log('Patient list queried successfully.', response.data.listPatients);
        setPatientsList(response.data.listPatients);
        return response.data.listPatients;
        } catch (error) {
        console.error('Error getting patients:', error);
        }
    }

    // Get list of all clinicians
    async function getAllClinicians() {
        try {
        const response = await client.graphql({ query: listClinicians });
        console.log('Clinician list queried successfully.', response.data.listClinicians);
        //setPatientData(response.data.listClinicians);
        return response.data.listClinicians;
        } catch (error) {
        console.error('Error getting clinicians:', error);
        }
    }

    // Get clinician information
    async function getClinicianData( clinicianId ) {
        try {
        const response = await client.graphql({ 
            query: getClinician, 
            variables: { id: clinicianId }
        });
        console.log('Clinician data queried successfully.', response.data.getClinician);
        return response.data.getClinician;
        } catch (error) {
        console.error('Error fetching clinician:', error);
        }
    }

    // [ADD INPUTS/UI] Add new clinician
    async function createClinicianData() {
        try {
        const response = await client.graphql({ 
            query: createClinician, 
            variables: { 
            input: {
                firstName: "",
                lastName: "",
                role: "",
                bio: "",
                imagePath: "",
            }
            }
        });
        console.log('Clinician created successfully.', response.data.createClinician);
        } catch (error) {
        console.error('Error creating clinician:', error);
        }
    }

    // Update patient information after they've created account
    async function updatePatientInfo( patientId, patientDetails ) {
        try {
        const response = await client.graphql({ 
            query: updatePatient, 
            variables: { 
                input: {
                    id: patientId,
                    ...patientDetails
                    // firstName: '',
                    // middleName: '',
                    // lastName: '',
                    // birthdate: '',
                    // email: '',
                    // phone: '',
                    // insurance: '',
                    // state: '',
                    // clinicName: ''
                }
            } 
        });
        console.log('Updated patient information successfully.', response.data.updatePatient);
        } catch (error) {
        console.error('Error updating patient\'s information:', error);
        }
    }

    // Assign dietitian to patient
    async function assignDietitiantoPatient( patientId, dietitianId ) {
        const patient_id = patientId;
        const dietitian_id = dietitianId;

        try {
        const response = await client.graphql({ 
            query: updatePatient, 
            variables: { 
            input: {
                id: patient_id,
                dietitianId: dietitian_id
            }
            }
        });

        console.log('Dietitian associated successfully', response.data.updatePatient);
        } catch (error) {
        console.error('Error associating dietitian:', error);
        }
    }

    // Assign coach to patient
    async function assignCoachtoPatient( patientId, healthCoachId ) {
        const patient_id = patientId;
        const healthCoach_id = healthCoachId;
        
        try {
        const response = await client.graphql({ 
            query: updatePatient, 
            variables: { 
            input: {
                id: patient_id,
                healthCoachId: healthCoach_id
            }
            }
        });

        console.log('Coach associated successfully', response.data.updatePatient);
        } catch (error) {
        console.error('Error associating coach:', error);
        }
    }

    // TODO [ADD INPUTS/UI] Create appointment and add to patient and clinician
    // use moment-timezone to store times in UTC or convert from local timezone (what is best way to store date in DB?)
    async function createAppointmentData(patientId, clinicianId) {
        try {
        const patient_id = patientId;
        const clinician_id = clinicianId;
        
        const clinician = await getClinicianData(clinician_id);
        console.log(clinician);

        const appointmentType = clinician.role;

        const response = await client.graphql({ 
            query: createAppointment, 
            variables: { 
            input: {
                // patientId: patient_id,
                // clinicianId: clinician_id,
                // startTime: '2024-01-20T15:30:00-06:00', //time zone
                // endTime: '2024-01-20T15:30:00-06:00', //time zone
                // appointmentType: appointmentType, // "Health Coach", "Dietitian"
                // appointmentLocation: "Zoom", // "Zoom", "Phone"
                // phoneNumber: patientData.phone,
                // zoomLink: clinician.zoomLink,
                // zoomInfo: clinician.zoomInfo
                patientId: patient_id,
                clinicianId: clinician_id,
                startTime: '2024-02-08T17:30:00-08:00', //time zone
                endTime: '2024-01-20T17:50:00-08:00', //time zone
                appointmentType: appointmentType, // "Health Coach", "Dietitian"
                appointmentLocation: "Zoom", // "Zoom", "Phone"
                phoneNumber: patientData.phone,
                zoomLink: clinician.zoomLink,
                zoomInfo: clinician.zoomInfo
            }
            }
        });
        console.log('Appointment created successfully.', response.data.createAppointment);
        } catch (error) {
        console.error('Error creating appointment:', error);
        }
    }

    // [ADD INPUTS/UI] Add appointment notes
    async function addAppointmentNotes() {
        try {
        const response = await client.graphql({ 
            query: updateAppointment, 
            variables: { 
            input: {
                id: '80784877-06d6-4b16-97b7-0797f5a702ce',
                notes: 'hello notes'
            }
            }
        });
        console.log('Appointment notes added successfully.', response.data.updateAppointment);
        } catch (error) {
        console.error('Error adding appointment notes:', error);
        }
    }

    // ERROR [ADD INPUTS/UI] Add goal to patient
    async function addGoaltoPatient() {
        try {
        const response = await client.graphql({ 
            query: createGoal, 
            variables: { 
            input: {
                q1_goal: "hello",
                q2_positive: "hello",
                q3_improve: "hello",
                q4_discuss: "hello",
                date: "2024-01-1T14:45:00-06:00",
                patientId: '5c3893da-6d29-4ff3-9568-e2251c57f905'
            }
            }
        });
        console.log('Goal added successfully.', response.data.createGoal);
        } catch (error) {
        console.error('Error adding goal:', error);
        }
    }

    // Add lifestyle plan document and date for patient
    async function addLifestylePlantoPatient(patientId, file) {
        try {
            const filename = `${patientId}_lifestyle`;
            const result = await uploadData({
              key: filename,
              data: file,
              options: {
                accessLevel: 'public'
            }
            }).result;
            setUploadStatus('File uploaded successfully!');
            console.log('Document added successfully.', result);
        } catch (error) {
        console.error('Error adding document:', error);
        setUploadStatus('Failed to upload file.');
        }
    }

    const handleFileChange = (event) => {
        if (event.target.files.length > 0) {
            const selectedFile = event.target.files[0];
            setFile(selectedFile);
            setFilename(selectedFile.name);
        }
    };

    const handlePatientChange = (event) => {
        const selectedPatient = patientsList.items.find(patient => patient.id === event.target.value);
        setPatientData(selectedPatient);
    };

    useEffect(() => {
        async function authUser() {
            try {
                if (!hasSetInitialPatient.current) {
                    const response = await getCurrentUser();
                    console.log("Auth User:", response.userId);
                    setPatient(response.userId);
                    hasSetInitialPatient.current = true;
                }
            } catch (err) {
                console.error(err);
            }
        }
        authUser();
    }, []);

    useEffect(() => { //all other useEffects
    if (patient) {
        getCurrentPatientData();
        getAllPatients();
        // getAllClinicians();
        // getClinicianData();
        // createClinicianData();
        // updatePatientInfo();
        // assignDietitiantoPatient()
        // assignCoachtoPatient()
        createAppointmentData('26fe3b8d-81c7-4ce5-98a4-a7c6329d776c', '862baf5e-a10a-4e0d-9d06-f2c5d48acc94');
        // addAppointmentNotes();
        // addGoaltoPatient();
        // addLifestylePlantoPatient();
    }
    }, [patient, patientsList]);

    return (
    <ThemeProvider theme={theme}>
        <Grid container>
        <div className="App"> 
            <Grid item xs={12}>
            {patientData ? (
              <>
              <Paper elevation={0} style={{ margin: '20px', paddingBottom: '2px', minHeight: '50px', marginTop: '10px', minWidth: '340px' }}>
                <Typography variant="h5" style={{ marginTop: '20px', marginBottom: '5px' }}> <b>Select Patient</b> </Typography>
                <FormControl fullWidth>
                    <InputLabel id="patient-select-label">Select Patient</InputLabel>
                    <Select labelId="patient-select-label" id="patient-select" value={patientData ? patientData.id : ''}
                        label="Select Patient" onChange={handlePatientChange}>
                        {/* {patientsList.items.map((pt) => (
                            <MenuItem key={pt.id} value={pt.id}>
                                {pt.firstName} {pt.lastName}: {pt.id}
                            </MenuItem>
                        ))} */}
                    </Select>
                </FormControl>

                <Typography variant="h5" style={{ marginTop: '20px', marginBottom: '5px' }}> <b>Update Patient Info</b> </Typography>
                {/* async function updatePatientInfo( patientId, patientDetails ) { */}
                    {/* // firstName: '',
                    // middleName: '',
                    // lastName: '',
                    // birthdate: '',
                    // email: '',
                    // phone: '',
                    // insurance: '',
                    // state: '',
                    // clinicName: '' */}
                
                <Typography variant="h5" style={{ marginTop: '20px', marginBottom: '5px' }}> <b>Assign Dietitian and Coach to Patient </b> </Typography>
                {/* async function assignDietitiantoPatient( patientId, dietitianId ) */}
                {/* async function assignCoachtoPatient( patientId, healthCoachId ) */}
                
                <Typography variant="h5" style={{ marginTop: '20px', marginBottom: '5px' }}> <b>Add Appointment to Patient </b> </Typography>
                {/* async function createAppointmentData(patientId, clinicianId) */}

                <Typography variant="h5" style={{ marginTop: '20px', marginBottom: '5px' }}> <b>Add Post-Visit Notes for Appointment </b> </Typography>
                {/* async function addAppointmentNotes() */}

                <Typography variant="h5" style={{ marginTop: '20px', marginBottom: '5px' }}> <b>Add Goal to Patient</b> </Typography>
                {/* async function addGoaltoPatient() */}

                <Typography variant="h5" style={{ marginTop: '20px', marginBottom: '5px' }}> <b>Add Lifestyle Plan to Patient</b> </Typography>
                <input type="file" style={{ height: '100px', fontSize: '16px' }} onChange={handleFileChange} />
                <Button variant="contained" color="primary" size="small" style={{ color: 'white', margin: '0' }}
                    onClick={() => addLifestylePlantoPatient(patientData.id, file)}>
                    Upload Document
                </Button>
                {uploadStatus && (
                    <Typography variant="body1" color="red" style={{ marginTop: '20px' }}>
                        {uploadStatus}
                    </Typography>
                )}

                </Paper>
              </>
            ) : (
              <CircularProgress />
            )}
            </Grid>
        </div>
        </Grid>
    </ThemeProvider>
    );
}