/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getPatient = /* GraphQL */ `
  query GetPatient($id: ID!) {
    getPatient(id: $id) {
      id
      firstName
      middleName
      lastName
      birthdate
      email
      phone
      insurance
      state
      clinicName
      healthCoachId
      healthCoach {
        id
        firstName
        lastName
        role
        bio
        patientsAsHealthCoach {
          items {
            id
            firstName
            middleName
            lastName
            birthdate
            email
            phone
            insurance
            state
            clinicName
            healthCoachId
            dietitianId
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        patientsAsDietitian {
          items {
            id
            firstName
            middleName
            lastName
            birthdate
            email
            phone
            insurance
            state
            clinicName
            healthCoachId
            dietitianId
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        appointments {
          items {
            id
            patientId
            clinicianId
            startTime
            endTime
            appointmentType
            appointmentLocation
            phoneNumber
            zoomLink
            zoomInfo
            notes
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        imagePath
        zoomLink
        zoomInfo
        createdAt
        updatedAt
        __typename
      }
      dietitianId
      dietitian {
        id
        firstName
        lastName
        role
        bio
        patientsAsHealthCoach {
          items {
            id
            firstName
            middleName
            lastName
            birthdate
            email
            phone
            insurance
            state
            clinicName
            healthCoachId
            dietitianId
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        patientsAsDietitian {
          items {
            id
            firstName
            middleName
            lastName
            birthdate
            email
            phone
            insurance
            state
            clinicName
            healthCoachId
            dietitianId
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        appointments {
          items {
            id
            patientId
            clinicianId
            startTime
            endTime
            appointmentType
            appointmentLocation
            phoneNumber
            zoomLink
            zoomInfo
            notes
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        imagePath
        zoomLink
        zoomInfo
        createdAt
        updatedAt
        __typename
      }
      appointments {
        items {
          id
          patientId
          patient {
            id
            firstName
            middleName
            lastName
            birthdate
            email
            phone
            insurance
            state
            clinicName
            healthCoachId
            dietitianId
            createdAt
            updatedAt
            owner
            __typename
          }
          clinicianId
          clinician {
            id
            firstName
            lastName
            role
            bio
            imagePath
            zoomLink
            zoomInfo
            createdAt
            updatedAt
            __typename
          }
          startTime
          endTime
          appointmentType
          appointmentLocation
          phoneNumber
          zoomLink
          zoomInfo
          notes
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      goals {
        items {
          id
          patientId
          patient {
            id
            firstName
            middleName
            lastName
            birthdate
            email
            phone
            insurance
            state
            clinicName
            healthCoachId
            dietitianId
            createdAt
            updatedAt
            owner
            __typename
          }
          q0_prevAchieved
          q1_goal
          q2_positive
          q3_improve
          q4_discuss
          startTime
          endTime
          achieved
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listPatients = /* GraphQL */ `
  query ListPatients(
    $filter: ModelPatientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPatients(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        middleName
        lastName
        birthdate
        email
        phone
        insurance
        state
        clinicName
        healthCoachId
        healthCoach {
          id
          firstName
          lastName
          role
          bio
          patientsAsHealthCoach {
            nextToken
            __typename
          }
          patientsAsDietitian {
            nextToken
            __typename
          }
          appointments {
            nextToken
            __typename
          }
          imagePath
          zoomLink
          zoomInfo
          createdAt
          updatedAt
          __typename
        }
        dietitianId
        dietitian {
          id
          firstName
          lastName
          role
          bio
          patientsAsHealthCoach {
            nextToken
            __typename
          }
          patientsAsDietitian {
            nextToken
            __typename
          }
          appointments {
            nextToken
            __typename
          }
          imagePath
          zoomLink
          zoomInfo
          createdAt
          updatedAt
          __typename
        }
        appointments {
          items {
            id
            patientId
            clinicianId
            startTime
            endTime
            appointmentType
            appointmentLocation
            phoneNumber
            zoomLink
            zoomInfo
            notes
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        goals {
          items {
            id
            patientId
            q0_prevAchieved
            q1_goal
            q2_positive
            q3_improve
            q4_discuss
            startTime
            endTime
            achieved
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const patientsByHealthCoachIdAndLastName = /* GraphQL */ `
  query PatientsByHealthCoachIdAndLastName(
    $healthCoachId: ID!
    $lastName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPatientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    patientsByHealthCoachIdAndLastName(
      healthCoachId: $healthCoachId
      lastName: $lastName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        middleName
        lastName
        birthdate
        email
        phone
        insurance
        state
        clinicName
        healthCoachId
        healthCoach {
          id
          firstName
          lastName
          role
          bio
          patientsAsHealthCoach {
            nextToken
            __typename
          }
          patientsAsDietitian {
            nextToken
            __typename
          }
          appointments {
            nextToken
            __typename
          }
          imagePath
          zoomLink
          zoomInfo
          createdAt
          updatedAt
          __typename
        }
        dietitianId
        dietitian {
          id
          firstName
          lastName
          role
          bio
          patientsAsHealthCoach {
            nextToken
            __typename
          }
          patientsAsDietitian {
            nextToken
            __typename
          }
          appointments {
            nextToken
            __typename
          }
          imagePath
          zoomLink
          zoomInfo
          createdAt
          updatedAt
          __typename
        }
        appointments {
          items {
            id
            patientId
            clinicianId
            startTime
            endTime
            appointmentType
            appointmentLocation
            phoneNumber
            zoomLink
            zoomInfo
            notes
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        goals {
          items {
            id
            patientId
            q0_prevAchieved
            q1_goal
            q2_positive
            q3_improve
            q4_discuss
            startTime
            endTime
            achieved
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const patientsByDietitianIdAndLastName = /* GraphQL */ `
  query PatientsByDietitianIdAndLastName(
    $dietitianId: ID!
    $lastName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPatientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    patientsByDietitianIdAndLastName(
      dietitianId: $dietitianId
      lastName: $lastName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        middleName
        lastName
        birthdate
        email
        phone
        insurance
        state
        clinicName
        healthCoachId
        healthCoach {
          id
          firstName
          lastName
          role
          bio
          patientsAsHealthCoach {
            nextToken
            __typename
          }
          patientsAsDietitian {
            nextToken
            __typename
          }
          appointments {
            nextToken
            __typename
          }
          imagePath
          zoomLink
          zoomInfo
          createdAt
          updatedAt
          __typename
        }
        dietitianId
        dietitian {
          id
          firstName
          lastName
          role
          bio
          patientsAsHealthCoach {
            nextToken
            __typename
          }
          patientsAsDietitian {
            nextToken
            __typename
          }
          appointments {
            nextToken
            __typename
          }
          imagePath
          zoomLink
          zoomInfo
          createdAt
          updatedAt
          __typename
        }
        appointments {
          items {
            id
            patientId
            clinicianId
            startTime
            endTime
            appointmentType
            appointmentLocation
            phoneNumber
            zoomLink
            zoomInfo
            notes
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        goals {
          items {
            id
            patientId
            q0_prevAchieved
            q1_goal
            q2_positive
            q3_improve
            q4_discuss
            startTime
            endTime
            achieved
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getClinician = /* GraphQL */ `
  query GetClinician($id: ID!) {
    getClinician(id: $id) {
      id
      firstName
      lastName
      role
      bio
      patientsAsHealthCoach {
        items {
          id
          firstName
          middleName
          lastName
          birthdate
          email
          phone
          insurance
          state
          clinicName
          healthCoachId
          healthCoach {
            id
            firstName
            lastName
            role
            bio
            imagePath
            zoomLink
            zoomInfo
            createdAt
            updatedAt
            __typename
          }
          dietitianId
          dietitian {
            id
            firstName
            lastName
            role
            bio
            imagePath
            zoomLink
            zoomInfo
            createdAt
            updatedAt
            __typename
          }
          appointments {
            nextToken
            __typename
          }
          goals {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      patientsAsDietitian {
        items {
          id
          firstName
          middleName
          lastName
          birthdate
          email
          phone
          insurance
          state
          clinicName
          healthCoachId
          healthCoach {
            id
            firstName
            lastName
            role
            bio
            imagePath
            zoomLink
            zoomInfo
            createdAt
            updatedAt
            __typename
          }
          dietitianId
          dietitian {
            id
            firstName
            lastName
            role
            bio
            imagePath
            zoomLink
            zoomInfo
            createdAt
            updatedAt
            __typename
          }
          appointments {
            nextToken
            __typename
          }
          goals {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      appointments {
        items {
          id
          patientId
          patient {
            id
            firstName
            middleName
            lastName
            birthdate
            email
            phone
            insurance
            state
            clinicName
            healthCoachId
            dietitianId
            createdAt
            updatedAt
            owner
            __typename
          }
          clinicianId
          clinician {
            id
            firstName
            lastName
            role
            bio
            imagePath
            zoomLink
            zoomInfo
            createdAt
            updatedAt
            __typename
          }
          startTime
          endTime
          appointmentType
          appointmentLocation
          phoneNumber
          zoomLink
          zoomInfo
          notes
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imagePath
      zoomLink
      zoomInfo
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listClinicians = /* GraphQL */ `
  query ListClinicians(
    $filter: ModelClinicianFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClinicians(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        role
        bio
        patientsAsHealthCoach {
          items {
            id
            firstName
            middleName
            lastName
            birthdate
            email
            phone
            insurance
            state
            clinicName
            healthCoachId
            dietitianId
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        patientsAsDietitian {
          items {
            id
            firstName
            middleName
            lastName
            birthdate
            email
            phone
            insurance
            state
            clinicName
            healthCoachId
            dietitianId
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        appointments {
          items {
            id
            patientId
            clinicianId
            startTime
            endTime
            appointmentType
            appointmentLocation
            phoneNumber
            zoomLink
            zoomInfo
            notes
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        imagePath
        zoomLink
        zoomInfo
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAppointment = /* GraphQL */ `
  query GetAppointment($id: ID!) {
    getAppointment(id: $id) {
      id
      patientId
      patient {
        id
        firstName
        middleName
        lastName
        birthdate
        email
        phone
        insurance
        state
        clinicName
        healthCoachId
        healthCoach {
          id
          firstName
          lastName
          role
          bio
          patientsAsHealthCoach {
            nextToken
            __typename
          }
          patientsAsDietitian {
            nextToken
            __typename
          }
          appointments {
            nextToken
            __typename
          }
          imagePath
          zoomLink
          zoomInfo
          createdAt
          updatedAt
          __typename
        }
        dietitianId
        dietitian {
          id
          firstName
          lastName
          role
          bio
          patientsAsHealthCoach {
            nextToken
            __typename
          }
          patientsAsDietitian {
            nextToken
            __typename
          }
          appointments {
            nextToken
            __typename
          }
          imagePath
          zoomLink
          zoomInfo
          createdAt
          updatedAt
          __typename
        }
        appointments {
          items {
            id
            patientId
            clinicianId
            startTime
            endTime
            appointmentType
            appointmentLocation
            phoneNumber
            zoomLink
            zoomInfo
            notes
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        goals {
          items {
            id
            patientId
            q0_prevAchieved
            q1_goal
            q2_positive
            q3_improve
            q4_discuss
            startTime
            endTime
            achieved
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      clinicianId
      clinician {
        id
        firstName
        lastName
        role
        bio
        patientsAsHealthCoach {
          items {
            id
            firstName
            middleName
            lastName
            birthdate
            email
            phone
            insurance
            state
            clinicName
            healthCoachId
            dietitianId
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        patientsAsDietitian {
          items {
            id
            firstName
            middleName
            lastName
            birthdate
            email
            phone
            insurance
            state
            clinicName
            healthCoachId
            dietitianId
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        appointments {
          items {
            id
            patientId
            clinicianId
            startTime
            endTime
            appointmentType
            appointmentLocation
            phoneNumber
            zoomLink
            zoomInfo
            notes
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        imagePath
        zoomLink
        zoomInfo
        createdAt
        updatedAt
        __typename
      }
      startTime
      endTime
      appointmentType
      appointmentLocation
      phoneNumber
      zoomLink
      zoomInfo
      notes
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAppointments = /* GraphQL */ `
  query ListAppointments(
    $filter: ModelAppointmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAppointments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        patientId
        patient {
          id
          firstName
          middleName
          lastName
          birthdate
          email
          phone
          insurance
          state
          clinicName
          healthCoachId
          healthCoach {
            id
            firstName
            lastName
            role
            bio
            imagePath
            zoomLink
            zoomInfo
            createdAt
            updatedAt
            __typename
          }
          dietitianId
          dietitian {
            id
            firstName
            lastName
            role
            bio
            imagePath
            zoomLink
            zoomInfo
            createdAt
            updatedAt
            __typename
          }
          appointments {
            nextToken
            __typename
          }
          goals {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        clinicianId
        clinician {
          id
          firstName
          lastName
          role
          bio
          patientsAsHealthCoach {
            nextToken
            __typename
          }
          patientsAsDietitian {
            nextToken
            __typename
          }
          appointments {
            nextToken
            __typename
          }
          imagePath
          zoomLink
          zoomInfo
          createdAt
          updatedAt
          __typename
        }
        startTime
        endTime
        appointmentType
        appointmentLocation
        phoneNumber
        zoomLink
        zoomInfo
        notes
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const appointmentsByPatientIdAndStartTime = /* GraphQL */ `
  query AppointmentsByPatientIdAndStartTime(
    $patientId: ID!
    $startTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAppointmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    appointmentsByPatientIdAndStartTime(
      patientId: $patientId
      startTime: $startTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        patientId
        patient {
          id
          firstName
          middleName
          lastName
          birthdate
          email
          phone
          insurance
          state
          clinicName
          healthCoachId
          healthCoach {
            id
            firstName
            lastName
            role
            bio
            imagePath
            zoomLink
            zoomInfo
            createdAt
            updatedAt
            __typename
          }
          dietitianId
          dietitian {
            id
            firstName
            lastName
            role
            bio
            imagePath
            zoomLink
            zoomInfo
            createdAt
            updatedAt
            __typename
          }
          appointments {
            nextToken
            __typename
          }
          goals {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        clinicianId
        clinician {
          id
          firstName
          lastName
          role
          bio
          patientsAsHealthCoach {
            nextToken
            __typename
          }
          patientsAsDietitian {
            nextToken
            __typename
          }
          appointments {
            nextToken
            __typename
          }
          imagePath
          zoomLink
          zoomInfo
          createdAt
          updatedAt
          __typename
        }
        startTime
        endTime
        appointmentType
        appointmentLocation
        phoneNumber
        zoomLink
        zoomInfo
        notes
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const appointmentsByClinicianIdAndStartTime = /* GraphQL */ `
  query AppointmentsByClinicianIdAndStartTime(
    $clinicianId: ID!
    $startTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAppointmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    appointmentsByClinicianIdAndStartTime(
      clinicianId: $clinicianId
      startTime: $startTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        patientId
        patient {
          id
          firstName
          middleName
          lastName
          birthdate
          email
          phone
          insurance
          state
          clinicName
          healthCoachId
          healthCoach {
            id
            firstName
            lastName
            role
            bio
            imagePath
            zoomLink
            zoomInfo
            createdAt
            updatedAt
            __typename
          }
          dietitianId
          dietitian {
            id
            firstName
            lastName
            role
            bio
            imagePath
            zoomLink
            zoomInfo
            createdAt
            updatedAt
            __typename
          }
          appointments {
            nextToken
            __typename
          }
          goals {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        clinicianId
        clinician {
          id
          firstName
          lastName
          role
          bio
          patientsAsHealthCoach {
            nextToken
            __typename
          }
          patientsAsDietitian {
            nextToken
            __typename
          }
          appointments {
            nextToken
            __typename
          }
          imagePath
          zoomLink
          zoomInfo
          createdAt
          updatedAt
          __typename
        }
        startTime
        endTime
        appointmentType
        appointmentLocation
        phoneNumber
        zoomLink
        zoomInfo
        notes
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getGoal = /* GraphQL */ `
  query GetGoal($id: ID!) {
    getGoal(id: $id) {
      id
      patientId
      patient {
        id
        firstName
        middleName
        lastName
        birthdate
        email
        phone
        insurance
        state
        clinicName
        healthCoachId
        healthCoach {
          id
          firstName
          lastName
          role
          bio
          patientsAsHealthCoach {
            nextToken
            __typename
          }
          patientsAsDietitian {
            nextToken
            __typename
          }
          appointments {
            nextToken
            __typename
          }
          imagePath
          zoomLink
          zoomInfo
          createdAt
          updatedAt
          __typename
        }
        dietitianId
        dietitian {
          id
          firstName
          lastName
          role
          bio
          patientsAsHealthCoach {
            nextToken
            __typename
          }
          patientsAsDietitian {
            nextToken
            __typename
          }
          appointments {
            nextToken
            __typename
          }
          imagePath
          zoomLink
          zoomInfo
          createdAt
          updatedAt
          __typename
        }
        appointments {
          items {
            id
            patientId
            clinicianId
            startTime
            endTime
            appointmentType
            appointmentLocation
            phoneNumber
            zoomLink
            zoomInfo
            notes
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        goals {
          items {
            id
            patientId
            q0_prevAchieved
            q1_goal
            q2_positive
            q3_improve
            q4_discuss
            startTime
            endTime
            achieved
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      q0_prevAchieved
      q1_goal
      q2_positive
      q3_improve
      q4_discuss
      startTime
      endTime
      achieved
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listGoals = /* GraphQL */ `
  query ListGoals(
    $filter: ModelGoalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGoals(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        patientId
        patient {
          id
          firstName
          middleName
          lastName
          birthdate
          email
          phone
          insurance
          state
          clinicName
          healthCoachId
          healthCoach {
            id
            firstName
            lastName
            role
            bio
            imagePath
            zoomLink
            zoomInfo
            createdAt
            updatedAt
            __typename
          }
          dietitianId
          dietitian {
            id
            firstName
            lastName
            role
            bio
            imagePath
            zoomLink
            zoomInfo
            createdAt
            updatedAt
            __typename
          }
          appointments {
            nextToken
            __typename
          }
          goals {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        q0_prevAchieved
        q1_goal
        q2_positive
        q3_improve
        q4_discuss
        startTime
        endTime
        achieved
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const goalsByPatientIdAndStartTime = /* GraphQL */ `
  query GoalsByPatientIdAndStartTime(
    $patientId: ID!
    $startTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGoalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    goalsByPatientIdAndStartTime(
      patientId: $patientId
      startTime: $startTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        patientId
        patient {
          id
          firstName
          middleName
          lastName
          birthdate
          email
          phone
          insurance
          state
          clinicName
          healthCoachId
          healthCoach {
            id
            firstName
            lastName
            role
            bio
            imagePath
            zoomLink
            zoomInfo
            createdAt
            updatedAt
            __typename
          }
          dietitianId
          dietitian {
            id
            firstName
            lastName
            role
            bio
            imagePath
            zoomLink
            zoomInfo
            createdAt
            updatedAt
            __typename
          }
          appointments {
            nextToken
            __typename
          }
          goals {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        q0_prevAchieved
        q1_goal
        q2_positive
        q3_improve
        q4_discuss
        startTime
        endTime
        achieved
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getHealthGuides = /* GraphQL */ `
  query GetHealthGuides($id: ID!) {
    getHealthGuides(id: $id) {
      id
      short_title
      title
      code
      minutes_read
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listHealthGuides = /* GraphQL */ `
  query ListHealthGuides(
    $filter: ModelHealthGuidesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHealthGuides(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        short_title
        title
        code
        minutes_read
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
