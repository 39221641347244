import React, { useEffect, useState, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Box, Button, CircularProgress, Grid, Modal, Paper, ThemeProvider, Typography } from '@mui/material';
import moment from 'moment';
import useMediaQuery from '@mui/material/useMediaQuery';
import { theme } from '../theme';
import goals_background from '../images/goals_background.png';

import { Amplify } from 'aws-amplify';
import { generateClient } from 'aws-amplify/api';
import { getCurrentUser } from 'aws-amplify/auth';
import config from '../amplifyconfiguration.json';

import { getPatient } from "../graphql/queries";
import { createGoal } from "../graphql/mutations";

Amplify.configure(config);
const client = generateClient({ authMode: 'apiKey' });

const modalStyle = {
  position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, 
  bgcolor: 'background.paper', boxShadow: 24, pt: 2, px: 4, pb: 3,
};

export default function HealthGoalsPage() {
  const [ user, setUser ] = useState("");
  const [ patientData, setPatientData ] = useState("");

  useEffect(() => { //authUser
    async function authUser() {
      try {
        const response = await getCurrentUser();
        // console.log("Auth User:", response.userId);
        setUser(response.userId);
      } catch (err) {
        console.log(err);
      }
    }
    authUser();
  }, []);

  const getPatientData = useCallback(async () => {
    try {
      const response = await client.graphql({ query: getPatient, variables: { id: user } });
      setPatientData(response.data.getPatient);
      // console.log("Patient Data:", response.data.getPatient);
    } catch (error) {
      console.error('Error getting patient data:', error);
    }
  }, [user, setPatientData]);

  useEffect(() => { //getPatientData
    if (user) {
      getPatientData();
    }
  }, [user, getPatientData]);

  const addGoaltoPatient = async () => {
    const now = new Date();
    const utcOffset = now.getTimezoneOffset() * 60000;
    const startOfCurrentWeek = new Date(now.getTime() + utcOffset);
    startOfCurrentWeek.setUTCHours(22, 0, 0, 0);
    startOfCurrentWeek.setUTCDate(startOfCurrentWeek.getUTCDate() - startOfCurrentWeek.getUTCDay());
    const endOfNextWeek = new Date(startOfCurrentWeek.getTime());
    endOfNextWeek.setUTCDate(startOfCurrentWeek.getUTCDate() + 7);

    const response = await client.graphql({ 
        query: createGoal, 
        variables: { 
        input: {
            patientId: patientData.id,
            startTime: startOfCurrentWeek.toISOString(),
            endTime: endOfNextWeek.toISOString()
        }
        }
    });
    // console.log('Goal created successfully', response.data.createGoal);
    return response.data.createGoal.id;
  }
  
  function CurrentGoal() {
    const isSMScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const boxSize = '80px';
    const navigate = useNavigate();

    const currentGoalSelect = () => {
      const currentDateTime = new Date().toISOString();
      const filterCurrentGoal = patientData.goals.items.filter(goal => 
        new Date(goal.endTime) >= new Date(currentDateTime) && 
        new Date(goal.startTime) <= new Date(currentDateTime)
      );
      return filterCurrentGoal;
    }
    const currentGoal = currentGoalSelect();
    const goalText = currentGoal && currentGoal.length > 0 ? currentGoal[0].q1_goal : '';
    const goalTypographyVariant = goalText.length > 40 ? 'body1' : 'h6';

    const handleNewGoalCheckIn = async () => {
      try {
        const newGoalId = await addGoaltoPatient();
        navigate(`/checkin/${newGoalId}`);
      } catch (error) {
        console.error('Error creating new goal:', error);
      }
    };

    return (
      <div>
        <Typography variant={isSMScreen ? 'h6' : 'h5'} style={{ margin: '20px', marginTop: '25px', marginBottom: '0px' }}> <b>Your Current Health Goal</b> </Typography>
        {currentGoal.length > 0 && currentGoal[0].q1_goal !== '' ? (
          <Paper elevation={2} style={{ margin: '20px', paddingBottom: '2px', minHeight: '50px', marginTop: '10px', minWidth: '340px' }}>
            <Box display="flex" justifyContent="center" alignItems="center" maxHeight="40%">
            <img src={goals_background} alt="content background"
              style={{ height: '50px', width: '100%', objectFit: 'cover' }} />
            </Box>
            <Box height="60%" margin="5%" textAlign="left">
              <Grid container spacing={2} alignItems="center">
              <Grid item xs={8} md={8}>
                <Typography variant={goalTypographyVariant} style={{ textAlign: 'left', margin: '0' }}> <b>{currentGoal[0].q1_goal}</b> </Typography>
                <Typography variant="body2" color="primary.main" style={{ textAlign: 'left', margin: '0' }}> <b> Week of {moment(currentGoal[0].startTime).local().format('MMM DD, YYYY')}</b> </Typography>
              </Grid>
              <Grid item xs={4} md={4}>
                <Box sx={{ position: 'relative', display: 'inline-flex', width: boxSize, height: boxSize,
                          overflow: 'hidden', alignItems: 'center', justifyContent: 'center' }}>
                  <Link to={`/checkin/${currentGoal[0].id}`} className="link">
                    <Button variant="contained" color="primary" size="small" style={{color:'white'}}> Update </Button>
                  </Link>
                </Box>
              </Grid>
              </Grid>
            </Box>
          </Paper>
        ) : (
          <Paper elevation={2} style={{ margin: '20px', paddingBottom: '2px', minHeight: '50px', marginTop: '10px', minWidth: '340px'  }}>
            <Box display="flex" justifyContent="center" alignItems="center" maxHeight="40%">
            <img src={goals_background} alt="background"
              style={{ height: '50px', width: '100%', objectFit: 'cover' }} />
            </Box>
            <Box height="60%" margin="5%" textAlign="left">
              <Grid container spacing={2} alignItems="center">
              <Grid item xs={8} md={8}>
                <Typography variant="h6" style={{ textAlign: 'left', margin: '0', whiteSpace: 'nowrap' }}> <b>Check In</b> </Typography>
                <Typography variant="body2" color="primary.main" style={{ textAlign: 'left', margin: '0' }}> <b> Set your health goal for this week! </b> </Typography>
              </Grid>
              <Grid item xs={4} md={4}>
                <Box sx={{ position: 'relative', display: 'inline-flex', height: boxSize,
                          overflow: 'hidden', alignItems: 'center', justifyContent: 'center' }}>
                    <Button variant="contained" color="primary" size="small" onClick={handleNewGoalCheckIn} style={{color:'white'}}> Check In </Button>
                </Box>
              </Grid>
              </Grid>
            </Box>
          </Paper>
        )}
      </div>
    )
  }

  function PastCheckIns() {
    const isSMScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const boxSize = '70px';

    const pastGoalsList = () => { //most recent to least recent
      const currentDateTime = new Date().toISOString();
      const filteredGoals = patientData.goals.items.filter(goal => 
        (goal.q1_goal || goal.q2_positive || goal.q3_improve || goal.q4_discuss) &&
        new Date(goal.startTime) < new Date(currentDateTime) &&
        new Date(goal.endTime) < new Date(currentDateTime)
      );
      const sortedGoals = filteredGoals.sort((a, b) => 
        new Date(b.endTime) - new Date(a.endTime)
      );
      return sortedGoals;
    }
    const goalsList = pastGoalsList();

    const [selectedGoalId, setSelectedGoalId] = useState(null);
    const handleNotesOpen = (goalId) => {
      setSelectedGoalId(goalId);
    };
    const handleNotesClose = () => {
      setSelectedGoalId(null);
    };

    return (
      <div>
        <Typography variant={isSMScreen ? 'h6' : 'h5'} style={{margin: '20px', marginTop: '15px', marginBottom: '0px'}}> <b>Past Check-Ins</b> </Typography>
        {goalsList.length > 0 ? (
          goalsList.map(goal => (
            <Paper elevation={2} key={goal.id} style={{ margin: '20px', padding: '10px', minHeight: '50px', marginTop: '10px', minWidth: '340px' }}>
              <Grid container spacing={2} alignItems="center">
              <Grid item xs={1} md={1}>
                  {goal.achieved ? (
                    <Typography variant={isSMScreen ? 'body1' : 'h6'} style={{ textAlign: 'left', margin: '0' }}> <b>🏆</b> </Typography>
                  ): ( <></> )}
              </Grid>
              <Grid item xs={7} md={7}>
                  {goal.q1_goal ? (
                    <Typography variant={isSMScreen ? 'body2' : 'h6'} style={{ textAlign: 'left', margin: '0' }}> <b>{goal.q1_goal}</b> </Typography>
                  ): (
                    <Typography variant={isSMScreen ? 'body2' : 'h6'} style={{ textAlign: 'left', margin: '0' }}> <b>No Goal</b> </Typography>
                  )}
                  <Typography variant="body2" color="primary.main" style={{ textAlign: 'left', margin: '0' }}> <b> Week of {moment(goal.startTime).local().format('MMM DD, YYYY')}</b> </Typography>
              </Grid>
              <Grid item xs={4} md={4}>
                <Box sx={{ position: 'relative', display: 'inline-flex', width: boxSize, height: boxSize,
                          overflow: 'hidden', alignItems: 'center', justifyContent: 'center' }}>
                  <Button variant="contained" color="primary" size="small" onClick={() => handleNotesOpen(goal.id)} style={{color:'white', whiteSpace: 'nowrap'}}> View </Button>
                  {selectedGoalId === goal.id && (
                  <Modal open={selectedGoalId === goal.id} onClose={handleNotesClose}>
                    <Box sx={modalStyle}>
                      <Typography variant="h6" color="primary.main" component="h2"> <b>Check-In – Week of {moment(goal.startTime).local().format('MMM DD, YYYY')}</b> </Typography>
                      {(goal.q2_positive || goal.q3_improve || goal.q4_discuss || goal.achieved) ? (
                        <>
                        <Typography variant="body1" sx={{ mt: 2 }}> <b>What is your health goal for this week?</b> </Typography>
                        <Typography variant="body1"> {goal.q1_goal} </Typography>

                        <Typography variant="body1" sx={{ mt: 2 }}> <b>What progress did you make towards your goals last week?</b> </Typography>
                        <Typography variant="body1"> {goal.q2_positive} </Typography>

                        <Typography variant="body1" sx={{ mt: 2 }}> <b>What do you want to improve on next week?</b></Typography>
                        <Typography variant="body1"> {goal.q3_improve} </Typography>

                        <Typography variant="body1" sx={{ mt: 2 }}> <b>Is there anything you want to discuss with your health coach in your next visit?</b> </Typography>
                        <Typography variant="body1"> {goal.q4_discuss} </Typography>

                        <Typography variant="body1" sx={{ mt: 2 }}> <b>Did you achieve this goal?</b> </Typography>
                        {goal.achieved ? (
                          <Typography variant="body1"> Achieved! 🏆 </Typography>
                        ): (
                          <Typography variant="body1"> Not achieved. </Typography>
                        )}
                        </>
                      ) : ( 
                        <Typography sx={{ mt: 2 }}> <i>No information for this check in.</i> </Typography>
                      )}
                    </Box>
                  </Modal>
                  )}
                </Box>
              </Grid>
              </Grid>
            </Paper>
          ))
        ) : (
          <Paper elevation={2} style={{ margin: '20px', padding: '10px', textAlign: 'center' }}>
            <Typography variant="body1">No Past Check-Ins</Typography>
            <Typography variant="body2">Your next check-in will show up here!</Typography>
          </Paper>
        )}
      </div>
    )
  }

  return (
    <ThemeProvider theme={theme}>
      <Grid container>
        <div className="App">
          <Grid item xs={12}>
            {patientData ? (
              <>
              <CurrentGoal />
              <PastCheckIns />
              </>
            ) : (
              <CircularProgress />
            )}
          </Grid>
          <Typography variant="body2" style={{ textAlign: 'left', paddingBottom: '20px' }}> 
            Powered by <a href="https://www.flairhealth.com" target="_blank" rel="noopener noreferrer"> Flair Health </a>
          </Typography>
        </div>
      </Grid>
    </ThemeProvider>
  );
}