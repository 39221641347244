import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, List, ListItem, ListItemText, Paper, ThemeProvider, Typography } from '@mui/material';
import { theme } from '../theme';

import HealthCoachingGuide from '../content/HealthCoachingGuide';
import IntermittentFasting from '../content/IntermittentFasting';
import AntiInflammatoryDiet from '../content/AntiInflammatoryDiet';

export default function HealthGuidesPage() {
  const { guideId } = useParams();
  const [selectedTab, setSelectedTab] = useState(guideId);

  function ContentText({ selectedTab }) {
    const tabContents = [
      <HealthCoachingGuide />, 
      <IntermittentFasting />,
      <AntiInflammatoryDiet />,
    ];
  
    return (
      <div>
        <Paper elevation={2} style={{ margin: '20px', padding: '20px', minHeight: '75px', marginTop: '10px' }}>
          {tabContents[selectedTab]}
        </Paper>
      </div>
    )
  }
  
  function Tabs({ setSelectedTab }) {  
    const tabs = ['What is Health Coaching?', 'Intermittent Fasting: A Beginner’s Guide', 'The Anti-Inflammatory Diet'];
  
    return (
      <div>
        <Paper elevation={0} style={{ padding: '20px'}}>
          <Typography variant="h6" style={{ textAlign: 'left', margin: '0' }}> <b>More Health Guides</b> </Typography>
          <List>
            {tabs.map((tab, index) => (
              <ListItem button key={index} onClick={() => setSelectedTab(index)}>
                <ListItemText primary={tab} style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}/>
              </ListItem>
            ))}
          </List>
        </Paper>
      </div>
    )
  }

  return (
    <ThemeProvider theme={theme}>
      <Grid container>
        <div className="content" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Grid container>
            <Grid item xs={12} md={8}>
              <ContentText selectedTab={selectedTab} />
            </Grid>
            <Grid item xs={12} md={4}>
              <Tabs setSelectedTab={setSelectedTab} />
            </Grid>
          </Grid>
        </div>
      </Grid>

      <Typography variant="body2" style={{ textAlign: 'center', paddingBottom: '20px' }}> 
        Powered by <a href="https://www.flairhealth.com" target="_blank" rel="noopener noreferrer"> Flair Health </a>
      </Typography>
    </ThemeProvider>
  );
}