import React, { useEffect, useState } from 'react';
import { Button, CircularProgress, Grid, ThemeProvider, Typography } from '@mui/material'; 
import useMediaQuery from '@mui/material/useMediaQuery';
import { theme } from '../theme';

import { Amplify } from 'aws-amplify';
import { generateClient } from 'aws-amplify/api';
import { getCurrentUser } from 'aws-amplify/auth';
import { list, downloadData } from 'aws-amplify/storage';
import config from '../amplifyconfiguration.json';

import { getPatient } from "../graphql/queries";

Amplify.configure(config);
const client = generateClient({ authMode: 'apiKey' });

export default function HomePage() {
  const isSMScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [ user, setUser ] = useState("");
  const [ patientData, setPatientData ] = useState(null);
  const [ allFiles, setAllFiles] = useState([]);
  const [ lifestylePlanFile, setLifestylePlanFile ] = useState(null);
  const [ lifestylePlanUrl, setLifestylePlanUrl ] = useState(null);

  useEffect(() => { //authUser
    async function authUser() {
      try {
        const response = await getCurrentUser();
        // console.log("Auth User:", response.userId);
        setUser(response.userId);
      } catch (err) {
        console.log(err);
      }
    }
    authUser();
  }, []);

  // Set patient data
  useEffect(() => {
    const getPatientData = async () => {
      try {
        const response = await client.graphql({ query: getPatient, variables: { id: user } });
        setPatientData(response.data.getPatient);
        // console.log("Patient Data:", response.data.getPatient);
      } catch (error) {
        console.error('Error getting patient data:', error);
      }
    };
  
    if (user) {
      getPatientData();
    }
  }, [user]);

  // Get the full list of patient lifestyle files
  useEffect(() => {
    const listAllFiles = async () => {
      try {
        const result = await list({ 
          options: {
            listAll: true
          } 
        });
        setAllFiles(result);
        console.log('Files:', result);
      } catch (error) {
        console.error('Error listing files:', error);
      }
    };
    listAllFiles();
  }, []);

  // Set patient's specific lifestyle file name
  useEffect(() => {
    if (patientData) {
      const lifestyleFileName = `${patientData.id}_lifestyle`;
      setLifestylePlanFile(lifestyleFileName);
      // console.log("Lifestyle File:", lifestyleFileName);
    }
  }, [patientData]);

  // Download lifestyle file if it is found in the file array
  useEffect(() => {
    const downloadLifestylePlanFile = async () => {
      if (lifestylePlanFile && allFiles.items && allFiles.items.length > 0) {
        const lifestyleFile = allFiles.items.find(file => file.key === lifestylePlanFile);
        if (lifestyleFile) {
          // console.log('Found lifestyle plan file:', lifestyleFile);
          try {
            const { body, eTag } = await downloadData({
              key: lifestyleFile.key,
              options: { accessLevel: 'guest'}
            }).result;

            const blob = new Blob([body], { type: 'application/pdf' });
            const url = URL.createObjectURL(blob);
            setLifestylePlanUrl(url);

          } catch (error) {
            console.error('Error downloading lifestyle plan file:', error);
          }
        } else {
          console.log('Lifestyle plan file not found');
        }
      }
    };
    downloadLifestylePlanFile();
  }, [lifestylePlanFile, allFiles]);

  const downloadPDF = async () => {
    try {
      const response = await fetch(lifestylePlanUrl);
      if (!response.ok) throw new Error('Network response was not ok.');
      const data = await response.blob();
  
      const blobUrl = URL.createObjectURL(data);
      const link = document.createElement('a');
      link.href = blobUrl;

      let filename;
      if (patientData?.firstName && patientData?.lastName) {
        filename = `${patientData.firstName}_${patientData.lastName}_Lifestyle_Plan.pdf`;
      } else {
        filename = 'Patient_Lifestyle_Plan.pdf';
      }
      link.download = filename;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container>
        <div className="App"> 
          <Grid item xs={12}>
            {patientData ? (
              <>
              {lifestylePlanUrl ? (
                <div style={{margin: '20px'}}>
                <Typography variant={isSMScreen ? 'h5' : 'h4'} style={{marginBottom: '0px'}}>
                  <b>{patientData.firstName}'s Lifestyle Plan </b> 
                </Typography>
                <Typography variant='body2' style={{marginTop: '20px', marginBottom: '20px'}}> 
                  This plan was designed by your dietitian and health coach to help you achieve your health goals. 
                  This is <i>your plan</i> – feel free to discuss with your health coach at any point if you have any questions or would like to make changes.
                </Typography>

                <Button variant="contained" color="primary" sx={{ color: 'white', marginBottom: '10px', }} onClick={downloadPDF}>
                  Download File
                </Button>

                <iframe title="lifestylePlan" src={lifestylePlanUrl} width="100%" height="1000px"
                  style={{ border: 'none', display: 'block', marginLeft: 'auto', marginRight: 'auto' }} //maxWidth: '100%', height: 'auto'
                ></iframe>
                
                </div>
              ) : (
                <Typography variant="body1" style={{ margin: '20px', textAlign: 'center' }}>
                  Your lifestyle plan is currently being created. 
                  Please ensure you've completed your patient assessment form via the link sent to your texts. 
                  If already done, your plan will be ready shortly. Check back soon!
                </Typography>
              )}
              </>
            ) : (
              <CircularProgress />
            )}
          </Grid>
          <Grid item xs={12}>
          <Typography variant="body2" style={{ textAlign: 'left', paddingBottom: '20px' }}> 
            Powered by <a href="https://www.flairhealth.com" target="_blank" rel="noopener noreferrer"> Flair Health </a>
          </Typography>
          </Grid>
        </div>
      </Grid>
    </ThemeProvider>
  );
}