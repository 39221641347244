import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "@aws-amplify/ui-react/styles.css";

import { Amplify } from 'aws-amplify';
import config from './amplifyconfiguration.json';
import { withAuthenticator } from "@aws-amplify/ui-react";

import HomePage from './components/HomePage';
import AppointmentPage from './components/Appointments';
import HealthGoalsPage from './components/HealthGoals';
import HealthGuidesPage from './components/HealthGuides';
import LifestylePlanPage from './components/LifestylePlan';
import CheckInForm from './components/CheckInForm';
import PatientProfile from "./components/PatientProfile";
import Navbar from './components/Navbar';
import Admin from "./components/Admin";
import NotAuth404 from "./components/NotAuth404";

Amplify.configure(config);

const App = ({ signOut, user }) => {
  return (
    <div>
    <BrowserRouter basename="/">
    <Navbar signout={signOut} />
      <Routes>
        <Route exact path="/" element={<HomePage />} />
        <Route path="/appointments" element={<AppointmentPage />} />
        <Route path="/goals" element={<HealthGoalsPage />} />
        <Route path="/guides/:guideId" element={<HealthGuidesPage />} />
        <Route path="/plan" element={<LifestylePlanPage />} />
        <Route path="/checkin/:goalId" element={<CheckInForm />} />
        <Route path="/profile" element={<PatientProfile />} />
        <Route path="/admin48Wg9-pa0scHoY8b6yeqzbFqyxr2E2pPMl5sI0qrTt-zsfOGlFF4STkJRdh9RGRR2qQvK3" element={<Admin />} />
        <Route path="*" element={<NotAuth404 />} />
      </Routes>
    </BrowserRouter>
    </div>
  );
};

export default withAuthenticator(App);