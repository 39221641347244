import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, Button, CircularProgress, Grid, Paper, ThemeProvider, Typography } from '@mui/material';
import moment from 'moment';
import useMediaQuery from '@mui/material/useMediaQuery';
import { theme } from '../theme';
import lifestyle_img from '../images/lifestyle.png';
import goals_background from '../images/goals_background.png';

import { Amplify } from 'aws-amplify';
import { generateClient } from 'aws-amplify/api';
import { getCurrentUser } from 'aws-amplify/auth';
import { list, downloadData } from 'aws-amplify/storage';
import config from '../amplifyconfiguration.json';

import { getPatient } from "../graphql/queries";

Amplify.configure(config);
const client = generateClient({ authMode: 'apiKey' });

export default function HomePage() {
  const isSMScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [ user, setUser ] = useState("");
  const [ patientData, setPatientData ] = useState(null);
  const [allFiles, setAllFiles] = useState([]);
  const [isLifestylePlanFound, setIsLifestylePlanFound] = useState(false);
  const [lifestylePlanDate, setLifestylePlanDate] = useState(null);

  useEffect(() => { //authUser
    async function authUser() {
      try {
        const response = await getCurrentUser();
        // console.log("Auth User:", response.userId);
        setUser(response.userId);
      } catch (err) {
        console.log(err);
      }
    }
    authUser();
  }, []);

  const saveJson = (data, filename) => {
    const fileData = JSON.stringify(data, null, 2); // Beautify the JSON
    const blob = new Blob([fileData], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  
    URL.revokeObjectURL(url);
  };

  useEffect(() => { //setPatientData
    const getPatientData = async () => {
      try {
        const response = await client.graphql({ query: getPatient, variables: { id: user } });
        setPatientData(response.data.getPatient);
        // console.log("Patient Data:", response.data.getPatient);
      } catch (error) {
        console.error('Error getting patient data:', error);
      }
    };
  
    if (user) {
      getPatientData();
    }
  }, [user]);

  // Check whether the lifestyle plan file exists
  useEffect(() => {
    const checkLifestyleFileExists = async () => {
      if (patientData) {
        try {
          const result = await list({ options: { listAll: true } });
          setAllFiles(result);

          const lifestyleFileName = `${patientData.id}_lifestyle`;
          const lifestyleFile = result.items.find(file => file.key === lifestyleFileName);

          if (lifestyleFile) {
            // console.log('Found lifestyle plan file:', lifestyleFile);
            setIsLifestylePlanFound(true);
            if (lifestyleFile.lastModified) {
              setLifestylePlanDate(lifestyleFile.lastModified);
            }
          } else {
            console.log('Lifestyle plan file not found');
            setIsLifestylePlanFound(false);
          }
        } catch (error) {
          console.error('Error checking lifestyle plan file:', error);
          setIsLifestylePlanFound(false);
        }
      }
    };
    checkLifestyleFileExists();
  }, [patientData]);

  function HealthGoals() {
    const isSMScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const boxSize = '80px';

    const currentGoalSelect = () => {
      const currentDateTime = new Date().toISOString();
      const filterCurrentGoal = patientData.goals.items.filter(goal => 
        new Date(goal.endTime) >= new Date(currentDateTime) && 
        new Date(goal.startTime) <= new Date(currentDateTime)
      );
      return filterCurrentGoal;
    }
    const currentGoal = currentGoalSelect();
    const goalText = currentGoal && currentGoal.length > 0 ? currentGoal[0].q1_goal : '';
    const goalTypographyVariant = goalText.length > 40 ? 'body1' : 'h6';

    return (
      <div>
        <Typography variant={isSMScreen ? 'h6' : 'h5'} style={{ margin: '20px', marginTop: '25px', marginBottom: '0px' }}> <b>Your Current Health Goal</b> </Typography>
        {currentGoal.length > 0 && currentGoal[0].q1_goal !== '' ? (
          <Paper elevation={2} style={{ margin: '20px', marginTop: '10px', paddingBottom: '2px' }}>
            <Box display="flex" justifyContent="center" alignItems="center" maxHeight="40%">
            <img src={goals_background} alt="goals background"
              style={{ height: '50px', width: '100%', objectFit: 'cover' }} />
            </Box>
            <Box height="60%" margin="5%" textAlign="left">
            <Grid container spacing={2} alignItems="center">
            <Grid item xs={8} md={8}>
              <Typography variant={goalTypographyVariant} style={{ textAlign: 'left', margin: '0' }}> <b>{currentGoal[0].q1_goal}</b> </Typography>
              <Typography variant="body2" color="primary.main" style={{ textAlign: 'left', margin: '0' }}> <b> Week of {moment(currentGoal[0].startTime).local().format('MMM DD, YYYY')}</b> </Typography>
            </Grid>
            <Grid item xs={4} md={4}>
              <Box sx={{ position: 'relative', display: 'inline-flex', width: boxSize, height: boxSize,
                        overflow: 'hidden', alignItems: 'center', justifyContent: 'center' }}>
                <Link to={`/goals`} className="link">
                  <Button variant="contained" color="primary" size="small" style={{color:'white'}}> View </Button>
                </Link>
              </Box>
            </Grid>
            </Grid>
            </Box>
          </Paper>
        ) : (
          <Paper elevation={2} style={{ margin: '20px', marginTop: '10px', paddingBottom: '2px' }}>
            <Box display="flex" justifyContent="center" alignItems="center" maxHeight="40%">
            <img src={goals_background} alt="goals background"
              style={{ height: '50px', width: '100%', objectFit: 'cover' }} />
            </Box>
            <Box height="60%" margin="5%" textAlign="left">
            <Grid container spacing={2} alignItems="center">
            <Grid item xs={8} md={8}>
              <Typography variant="h6" style={{ textAlign: 'left', margin: '0', whiteSpace: 'nowrap' }}> <b>Check In</b> </Typography>
              <Typography variant="body2" color="primary.main" style={{ textAlign: 'left', margin: '0' }}> <b> Set your health goal for this week! </b> </Typography>
            </Grid>
            <Grid item xs={4} md={4}>
              <Box sx={{ position: 'relative', display: 'inline-flex', width: boxSize, height: boxSize,
                        overflow: 'hidden', alignItems: 'center', justifyContent: 'center' }}>
                <Link to={`/goals`} className="link">
                  <Button variant="contained" color="primary" size="small" style={{color:'white'}}> Check In </Button>
                </Link>
              </Box>
            </Grid>
            </Grid>
            </Box>
          </Paper>
        )}
      </div>
    )
  }
  
  function LifestylePlan() {
    const isSMScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const boxSize = isSMScreen ? '75px' : '100px';

    return (
      <div>
        <Typography variant={isSMScreen ? 'h6' : 'h5'} style={{margin: '20px', marginTop: '25px', marginBottom: '0px'}}> <b>Lifestyle Plan</b> </Typography>
        {isLifestylePlanFound ? (
          <Paper elevation={2} style={{ margin: '20px', padding: '20px', minHeight: '75px', marginTop: '10px' }}>
            <Grid container spacing={2} alignItems="center">
            <Grid item xs={8} md={8}>
              <Typography variant={isSMScreen ? 'body1' : 'h6'} style={{ textAlign: 'left', margin: '0' }}> <b>Lifestyle Plan</b> </Typography>
              { lifestylePlanDate && (
                <Typography variant="body2" color="primary" style={{ textAlign: 'left', margin: '0', paddingBottom: '10px' }}>
                  <b>Updated on {lifestylePlanDate.toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'short',
                    day: '2-digit'
                  })}</b>
                </Typography>
              )}
              <Link to="/plan" className="link">
                <Button variant="contained" color="primary" size="small" style={{color:'white'}} > View Plan </Button>
              </Link>
            </Grid>
            <Grid item xs={4} md={4}>
              <Box sx={{ position: 'relative', display: 'inline-flex', width: boxSize, height: boxSize,
                        borderRadius: '50%', overflow: 'hidden', alignItems: 'center', justifyContent: 'center' }}>
                <img src={lifestyle_img} alt="appointment" style={{ width: '100%', height: '100%', objectFit: 'cover' }}/>
              </Box>
            </Grid>
            </Grid>
          </Paper>
        ) : (
          <Paper elevation={2} style={{ margin: '20px', padding: '20px' }}>
            <Grid container spacing={2} alignItems="center">
            <Grid item xs={8} md={8}>
              <Typography variant="body1"> <b>Lifestyle Plan In Progress</b> </Typography>
              <Typography variant="body2" color="primary" sx={{ marginBottom: '5px' }} > <b>We're still working on your lifestyle plan – check back soon!</b> </Typography>
              <Link to="/plan" className="link">
                <Button variant="contained" color="primary" size="small" style={{color:'white'}} > More Info </Button>
              </Link>
            </Grid>
            <Grid item xs={4} md={4}>
              <Box sx={{ position: 'relative', display: 'inline-flex', width: boxSize, height: boxSize,
                        borderRadius: '50%', overflow: 'hidden', alignItems: 'center', justifyContent: 'center' }}>
                <img src={lifestyle_img} alt="appointment" style={{ width: '100%', height: '100%', objectFit: 'cover' }}/>
              </Box>
            </Grid>
            </Grid>
          </Paper>
        )}
      </div>
    )
  }

  function UpcomingAppointments() {
    const boxSize = isSMScreen ? '75px' : '100px';
    const [ clinicianImages, setClinicianImages ] = useState([]);

    // const getImagePath = (img) => {
    //   if (!patientData || !patientData.healthCoach || !patientData.healthCoach.imagePath) {
    //     return <img src={empty_profile} alt="profile" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />;
    //   }
    //   switch (img) {
    //     case 'kendall_cwik':
    //       return <img src={kendall_cwik} alt="kc" style={{ width: '100%', height: '100%', objectFit: 'cover' }}/>;
    //     case 'bonnie_kuss':
    //       return <img src={bonnie_kuss} alt="bk" style={{ width: '100%', height: '100%', objectFit: 'cover' }}/>;
    //     case 'heather_finn':
    //       return <img src={heather_finn} alt="hf" style={{ width: '100%', height: '100%', objectFit: 'cover' }}/>;
    //     default:
    //       return <img src={empty_profile} alt="profile" style={{ width: '100%', height: '100%', objectFit: 'cover' }}/>;
    //   }
    // };

    const nextUpcomingAppointment = () => {
      const currentDateTime = new Date().toISOString();
      const filteredAppointments = patientData.appointments.items.filter(appointment => 
        new Date(appointment.endTime) >= new Date(currentDateTime)
      );
      const sortedAppointments = filteredAppointments.sort((a, b) => 
        new Date(a.endTime) - new Date(b.endTime)
      );
      return sortedAppointments[0];
    }
    const nextAppointment = nextUpcomingAppointment();

    const currentAppointmentFlag = (appt) => {
      const currentDateTime = new Date().toISOString();
      if (new Date(appt.startTime) <= new Date(currentDateTime)) {
            return  <Box sx={{ position: 'relative', display: 'flex', height: '30px', overflow: 'hidden', alignItems: 'center', justifyContent: 'left' }}>
              <Typography variant='body2' color="red" style={{ textAlign: 'left', marginBottom: '10px' }}> <b>Happening Now!</b> </Typography>
              </Box>
      }
    };

    const currentAppointmentButton = (appt) => {
      const currentDateTime = new Date().toISOString();
      if (new Date(appt.startTime) <= new Date(currentDateTime)) {
            return  <Link to="/appointments" className="link">
                      <Button variant="contained" color="primary" size="small" style={{color:'white'}} > Join Now </Button>
                    </Link>
      } else {
        return <Link to="/appointments" className="link">
                <Button variant="contained" color="primary" size="small" style={{color:'white'}} > More Info </Button>
              </Link>
      }
    };

    useEffect(() => { //fetchImages
      const fetchImages = async () => {
        const imagePath = nextAppointment.clinician.imagePath;
        if (imagePath && !clinicianImages[imagePath]) {
          try {
            const { body, eTag } = await downloadData({
              key: `${nextAppointment.clinician.imagePath}.jpeg`,
              options: { accessLevel: 'guest'}
            }).result;
            const blob = new Blob([body], { type: 'image/jpeg' });
            const url = URL.createObjectURL(blob);
            setClinicianImages(prevUrls => ({ ...prevUrls, [imagePath]: url }));
          } catch (error) {
            const { body, eTag } = await downloadData({
              key: `empty_profile.jpeg`,
              options: { accessLevel: 'guest'}
            }).result;
            const blob = new Blob([body], { type: 'image/jpeg' });
            const url = URL.createObjectURL(blob);
            setClinicianImages(prevUrls => ({ ...prevUrls, [imagePath]: url }));
            console.error('Error downloading image:', error);
          }
        }
      };
      fetchImages();
      console.log(clinicianImages);
    }, [clinicianImages, nextAppointment]);
  
    return (
      <div>
        <Typography variant={isSMScreen ? 'h6' : 'h5'} style={{margin: '20px', marginTop: '25px', marginBottom: '0px'}}> <b>Upcoming Appointments</b> </Typography>
        {nextAppointment ? (
          <Paper elevation={2} key={nextAppointment.id} style={{ margin: '20px', padding: '20px', minHeight: '75px', marginTop: '10px', flex: 1 }}>
            {currentAppointmentFlag(nextAppointment)}
            <Grid container spacing={2} alignItems="center" style={{ display: 'flex', flexGrow: 1 }}>
            <Grid item xs={8} md={8}>
              <Typography variant={isSMScreen ? 'body1' : 'h6'} style={{ textAlign: 'left', margin: '0' }}> <b>{nextAppointment.clinician.firstName} {nextAppointment.clinician.lastName}</b> </Typography>
              <Typography variant="body2" color="primary.main" style={{ textAlign: 'left', margin: '0', paddingBottom: '5px' }}> <b>{moment(nextAppointment.startTime).local().format('MMM DD, YYYY [at] h:mm A')} ({moment(nextAppointment.endTime).diff(moment(nextAppointment.startTime), 'minutes')} mins)</b> </Typography>
              {currentAppointmentButton(nextAppointment)}
            </Grid>
            <Grid item xs={4} md={4}>
              <Box sx={{ position: 'relative', display: 'flex', width: boxSize, height: boxSize, 
                        borderRadius: '50%', overflow: 'hidden', alignItems: 'center', justifyContent: 'center' }}>
                <img src={clinicianImages[nextAppointment.clinician.imagePath]} alt="Clinician" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
              </Box>
            </Grid>
            </Grid>
          </Paper>
        ) : (
          <Paper elevation={2} style={{ margin: '20px', padding: '20px', textAlign: 'center' }}>
            <Typography variant="body1"> <b>No Upcoming Appointments</b> </Typography>
            <Typography variant="body2" color="primary" sx={{ marginBottom: '5px' }}> <b>Text our helpline at (833)658-5015 to schedule an appointment.</b> </Typography>
            <Link to="/appointments" className="link">
              <Button variant="contained" color="primary" size="small" style={{color:'white'}} > More Info </Button>
            </Link>
          </Paper>
        )}
      </div>
    )
  }
  
  function LifestyleContent() {
    const isSMScreen = useMediaQuery(theme.breakpoints.down('sm'));
  
    return (
      <div>
        <Typography variant={isSMScreen ? 'h6' : 'h5'} style={{ margin: '20px', marginTop: '25px', marginBottom: '0px' }}> <b>Health Guides</b> </Typography>
        <Grid container style={{ marginBottom: '0px' }} >
          <Grid item xs={6}>
            <Paper elevation={2} style={{ margin: '20px', marginTop: '10px' }}>
              <Box display="flex" justifyContent="center" alignItems="center" maxHeight="40%">
              <img src="https://pictures.alignable.com/eyJidWNrZXQiOiJhbGlnbmFibGV3ZWItcHJvZHVjdGlvbiIsImtleSI6InNlcnZpY2VzL3BpY3R1cmVzL21lZGl1bS85NzUyNTYvMTU1MjM0NjE0N19Nb2QuanBnIiwiZWRpdHMiOnt9fQ==" alt="content background"
                style={{ height: '50px', width: '100%', objectFit: 'cover' }} />
              </Box>
              <Box height="60%" padding="5%" textAlign="left">
                <Typography variant={isSMScreen ? 'body1' : 'h6'} style={{ textAlign: 'left', margin: '0' }}> <b>Health Coaching 101</b> </Typography>
                <Typography variant="body2" color="primary.main" style={{ textAlign: 'left', margin: '0', paddingBottom: '5px' }}> <b>5 min read</b> </Typography>
                <Link to="/guides/0" className="link">
                  <Button variant="contained" color="primary" size="small" style={{color:'white'}} > Read </Button>
                </Link>
              </Box>
            </Paper>
          </Grid>
  
          <Grid item xs={6}>
            <Paper elevation={2} style={{ margin: '20px', marginTop: '10px' }}>
                <Box display="flex" justifyContent="center" alignItems="center" maxHeight="40%">
                <img src="https://images.immediate.co.uk/production/volatile/sites/30/2023/11/Intermittant-fasting-7e65fbb.jpg?quality=90&resize=440,400" alt="content background"
                  style={{ height: '50px', width: '100%', objectFit: 'cover' }} />
                </Box>
                <Box height="60%" padding="5%" textAlign="left">
                  <Typography variant={isSMScreen ? 'body1' : 'h6'} style={{ textAlign: 'left', margin: '0' }}> <b>Intermittent Fasting</b> </Typography>
                  <Typography variant="body2" color="primary.main" style={{ textAlign: 'left', margin: '0', paddingBottom: '5px' }}> <b>5 min read</b> </Typography>
                  <Link to="/guides/1" className="link">
                    <Button variant="contained" color="primary" size="small" style={{color:'white'}} > Read </Button>
                  </Link>
                </Box>
              </Paper>
          </Grid>
        </Grid>
      </div>
    )
  }
  
  return (
    <ThemeProvider theme={theme}>
      <Grid container>
        <div className="App">
          <Grid item xs={12}> 
            {patientData ? (
              <>
              <Typography variant={isSMScreen ? 'h5' : 'h4'} style={{margin: '20px', marginBottom: '0px'}}> <b>Welcome Back, {patientData.firstName}! </b> </Typography>
              <HealthGoals />
              <LifestylePlan />
              <UpcomingAppointments />
              <LifestyleContent />
              </>
            ) : (
              <CircularProgress />
            )}
          </Grid>
          <Grid item xs={12}>
          <Typography variant="body2" style={{ textAlign: 'left', paddingBottom: '20px' }}> 
            Powered by <a href="https://www.flairhealth.com" target="_blank" rel="noopener noreferrer"> Flair Health </a>
          </Typography>
          </Grid>
        </div>
      </Grid>
    </ThemeProvider>
  );
}