/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createPatient = /* GraphQL */ `
  mutation CreatePatient(
    $input: CreatePatientInput!
    $condition: ModelPatientConditionInput
  ) {
    createPatient(input: $input, condition: $condition) {
      id
      firstName
      middleName
      lastName
      birthdate
      email
      phone
      insurance
      state
      clinicName
      healthCoachId
      healthCoach {
        id
        firstName
        lastName
        role
        bio
        patientsAsHealthCoach {
          items {
            id
            firstName
            middleName
            lastName
            birthdate
            email
            phone
            insurance
            state
            clinicName
            healthCoachId
            dietitianId
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        patientsAsDietitian {
          items {
            id
            firstName
            middleName
            lastName
            birthdate
            email
            phone
            insurance
            state
            clinicName
            healthCoachId
            dietitianId
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        appointments {
          items {
            id
            patientId
            clinicianId
            startTime
            endTime
            appointmentType
            appointmentLocation
            phoneNumber
            zoomLink
            zoomInfo
            notes
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        imagePath
        zoomLink
        zoomInfo
        createdAt
        updatedAt
        __typename
      }
      dietitianId
      dietitian {
        id
        firstName
        lastName
        role
        bio
        patientsAsHealthCoach {
          items {
            id
            firstName
            middleName
            lastName
            birthdate
            email
            phone
            insurance
            state
            clinicName
            healthCoachId
            dietitianId
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        patientsAsDietitian {
          items {
            id
            firstName
            middleName
            lastName
            birthdate
            email
            phone
            insurance
            state
            clinicName
            healthCoachId
            dietitianId
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        appointments {
          items {
            id
            patientId
            clinicianId
            startTime
            endTime
            appointmentType
            appointmentLocation
            phoneNumber
            zoomLink
            zoomInfo
            notes
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        imagePath
        zoomLink
        zoomInfo
        createdAt
        updatedAt
        __typename
      }
      appointments {
        items {
          id
          patientId
          patient {
            id
            firstName
            middleName
            lastName
            birthdate
            email
            phone
            insurance
            state
            clinicName
            healthCoachId
            dietitianId
            createdAt
            updatedAt
            owner
            __typename
          }
          clinicianId
          clinician {
            id
            firstName
            lastName
            role
            bio
            imagePath
            zoomLink
            zoomInfo
            createdAt
            updatedAt
            __typename
          }
          startTime
          endTime
          appointmentType
          appointmentLocation
          phoneNumber
          zoomLink
          zoomInfo
          notes
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      goals {
        items {
          id
          patientId
          patient {
            id
            firstName
            middleName
            lastName
            birthdate
            email
            phone
            insurance
            state
            clinicName
            healthCoachId
            dietitianId
            createdAt
            updatedAt
            owner
            __typename
          }
          q0_prevAchieved
          q1_goal
          q2_positive
          q3_improve
          q4_discuss
          startTime
          endTime
          achieved
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updatePatient = /* GraphQL */ `
  mutation UpdatePatient(
    $input: UpdatePatientInput!
    $condition: ModelPatientConditionInput
  ) {
    updatePatient(input: $input, condition: $condition) {
      id
      firstName
      middleName
      lastName
      birthdate
      email
      phone
      insurance
      state
      clinicName
      healthCoachId
      healthCoach {
        id
        firstName
        lastName
        role
        bio
        patientsAsHealthCoach {
          items {
            id
            firstName
            middleName
            lastName
            birthdate
            email
            phone
            insurance
            state
            clinicName
            healthCoachId
            dietitianId
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        patientsAsDietitian {
          items {
            id
            firstName
            middleName
            lastName
            birthdate
            email
            phone
            insurance
            state
            clinicName
            healthCoachId
            dietitianId
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        appointments {
          items {
            id
            patientId
            clinicianId
            startTime
            endTime
            appointmentType
            appointmentLocation
            phoneNumber
            zoomLink
            zoomInfo
            notes
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        imagePath
        zoomLink
        zoomInfo
        createdAt
        updatedAt
        __typename
      }
      dietitianId
      dietitian {
        id
        firstName
        lastName
        role
        bio
        patientsAsHealthCoach {
          items {
            id
            firstName
            middleName
            lastName
            birthdate
            email
            phone
            insurance
            state
            clinicName
            healthCoachId
            dietitianId
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        patientsAsDietitian {
          items {
            id
            firstName
            middleName
            lastName
            birthdate
            email
            phone
            insurance
            state
            clinicName
            healthCoachId
            dietitianId
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        appointments {
          items {
            id
            patientId
            clinicianId
            startTime
            endTime
            appointmentType
            appointmentLocation
            phoneNumber
            zoomLink
            zoomInfo
            notes
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        imagePath
        zoomLink
        zoomInfo
        createdAt
        updatedAt
        __typename
      }
      appointments {
        items {
          id
          patientId
          patient {
            id
            firstName
            middleName
            lastName
            birthdate
            email
            phone
            insurance
            state
            clinicName
            healthCoachId
            dietitianId
            createdAt
            updatedAt
            owner
            __typename
          }
          clinicianId
          clinician {
            id
            firstName
            lastName
            role
            bio
            imagePath
            zoomLink
            zoomInfo
            createdAt
            updatedAt
            __typename
          }
          startTime
          endTime
          appointmentType
          appointmentLocation
          phoneNumber
          zoomLink
          zoomInfo
          notes
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      goals {
        items {
          id
          patientId
          patient {
            id
            firstName
            middleName
            lastName
            birthdate
            email
            phone
            insurance
            state
            clinicName
            healthCoachId
            dietitianId
            createdAt
            updatedAt
            owner
            __typename
          }
          q0_prevAchieved
          q1_goal
          q2_positive
          q3_improve
          q4_discuss
          startTime
          endTime
          achieved
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deletePatient = /* GraphQL */ `
  mutation DeletePatient(
    $input: DeletePatientInput!
    $condition: ModelPatientConditionInput
  ) {
    deletePatient(input: $input, condition: $condition) {
      id
      firstName
      middleName
      lastName
      birthdate
      email
      phone
      insurance
      state
      clinicName
      healthCoachId
      healthCoach {
        id
        firstName
        lastName
        role
        bio
        patientsAsHealthCoach {
          items {
            id
            firstName
            middleName
            lastName
            birthdate
            email
            phone
            insurance
            state
            clinicName
            healthCoachId
            dietitianId
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        patientsAsDietitian {
          items {
            id
            firstName
            middleName
            lastName
            birthdate
            email
            phone
            insurance
            state
            clinicName
            healthCoachId
            dietitianId
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        appointments {
          items {
            id
            patientId
            clinicianId
            startTime
            endTime
            appointmentType
            appointmentLocation
            phoneNumber
            zoomLink
            zoomInfo
            notes
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        imagePath
        zoomLink
        zoomInfo
        createdAt
        updatedAt
        __typename
      }
      dietitianId
      dietitian {
        id
        firstName
        lastName
        role
        bio
        patientsAsHealthCoach {
          items {
            id
            firstName
            middleName
            lastName
            birthdate
            email
            phone
            insurance
            state
            clinicName
            healthCoachId
            dietitianId
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        patientsAsDietitian {
          items {
            id
            firstName
            middleName
            lastName
            birthdate
            email
            phone
            insurance
            state
            clinicName
            healthCoachId
            dietitianId
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        appointments {
          items {
            id
            patientId
            clinicianId
            startTime
            endTime
            appointmentType
            appointmentLocation
            phoneNumber
            zoomLink
            zoomInfo
            notes
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        imagePath
        zoomLink
        zoomInfo
        createdAt
        updatedAt
        __typename
      }
      appointments {
        items {
          id
          patientId
          patient {
            id
            firstName
            middleName
            lastName
            birthdate
            email
            phone
            insurance
            state
            clinicName
            healthCoachId
            dietitianId
            createdAt
            updatedAt
            owner
            __typename
          }
          clinicianId
          clinician {
            id
            firstName
            lastName
            role
            bio
            imagePath
            zoomLink
            zoomInfo
            createdAt
            updatedAt
            __typename
          }
          startTime
          endTime
          appointmentType
          appointmentLocation
          phoneNumber
          zoomLink
          zoomInfo
          notes
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      goals {
        items {
          id
          patientId
          patient {
            id
            firstName
            middleName
            lastName
            birthdate
            email
            phone
            insurance
            state
            clinicName
            healthCoachId
            dietitianId
            createdAt
            updatedAt
            owner
            __typename
          }
          q0_prevAchieved
          q1_goal
          q2_positive
          q3_improve
          q4_discuss
          startTime
          endTime
          achieved
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createClinician = /* GraphQL */ `
  mutation CreateClinician(
    $input: CreateClinicianInput!
    $condition: ModelClinicianConditionInput
  ) {
    createClinician(input: $input, condition: $condition) {
      id
      firstName
      lastName
      role
      bio
      patientsAsHealthCoach {
        items {
          id
          firstName
          middleName
          lastName
          birthdate
          email
          phone
          insurance
          state
          clinicName
          healthCoachId
          healthCoach {
            id
            firstName
            lastName
            role
            bio
            imagePath
            zoomLink
            zoomInfo
            createdAt
            updatedAt
            __typename
          }
          dietitianId
          dietitian {
            id
            firstName
            lastName
            role
            bio
            imagePath
            zoomLink
            zoomInfo
            createdAt
            updatedAt
            __typename
          }
          appointments {
            nextToken
            __typename
          }
          goals {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      patientsAsDietitian {
        items {
          id
          firstName
          middleName
          lastName
          birthdate
          email
          phone
          insurance
          state
          clinicName
          healthCoachId
          healthCoach {
            id
            firstName
            lastName
            role
            bio
            imagePath
            zoomLink
            zoomInfo
            createdAt
            updatedAt
            __typename
          }
          dietitianId
          dietitian {
            id
            firstName
            lastName
            role
            bio
            imagePath
            zoomLink
            zoomInfo
            createdAt
            updatedAt
            __typename
          }
          appointments {
            nextToken
            __typename
          }
          goals {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      appointments {
        items {
          id
          patientId
          patient {
            id
            firstName
            middleName
            lastName
            birthdate
            email
            phone
            insurance
            state
            clinicName
            healthCoachId
            dietitianId
            createdAt
            updatedAt
            owner
            __typename
          }
          clinicianId
          clinician {
            id
            firstName
            lastName
            role
            bio
            imagePath
            zoomLink
            zoomInfo
            createdAt
            updatedAt
            __typename
          }
          startTime
          endTime
          appointmentType
          appointmentLocation
          phoneNumber
          zoomLink
          zoomInfo
          notes
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imagePath
      zoomLink
      zoomInfo
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateClinician = /* GraphQL */ `
  mutation UpdateClinician(
    $input: UpdateClinicianInput!
    $condition: ModelClinicianConditionInput
  ) {
    updateClinician(input: $input, condition: $condition) {
      id
      firstName
      lastName
      role
      bio
      patientsAsHealthCoach {
        items {
          id
          firstName
          middleName
          lastName
          birthdate
          email
          phone
          insurance
          state
          clinicName
          healthCoachId
          healthCoach {
            id
            firstName
            lastName
            role
            bio
            imagePath
            zoomLink
            zoomInfo
            createdAt
            updatedAt
            __typename
          }
          dietitianId
          dietitian {
            id
            firstName
            lastName
            role
            bio
            imagePath
            zoomLink
            zoomInfo
            createdAt
            updatedAt
            __typename
          }
          appointments {
            nextToken
            __typename
          }
          goals {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      patientsAsDietitian {
        items {
          id
          firstName
          middleName
          lastName
          birthdate
          email
          phone
          insurance
          state
          clinicName
          healthCoachId
          healthCoach {
            id
            firstName
            lastName
            role
            bio
            imagePath
            zoomLink
            zoomInfo
            createdAt
            updatedAt
            __typename
          }
          dietitianId
          dietitian {
            id
            firstName
            lastName
            role
            bio
            imagePath
            zoomLink
            zoomInfo
            createdAt
            updatedAt
            __typename
          }
          appointments {
            nextToken
            __typename
          }
          goals {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      appointments {
        items {
          id
          patientId
          patient {
            id
            firstName
            middleName
            lastName
            birthdate
            email
            phone
            insurance
            state
            clinicName
            healthCoachId
            dietitianId
            createdAt
            updatedAt
            owner
            __typename
          }
          clinicianId
          clinician {
            id
            firstName
            lastName
            role
            bio
            imagePath
            zoomLink
            zoomInfo
            createdAt
            updatedAt
            __typename
          }
          startTime
          endTime
          appointmentType
          appointmentLocation
          phoneNumber
          zoomLink
          zoomInfo
          notes
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imagePath
      zoomLink
      zoomInfo
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteClinician = /* GraphQL */ `
  mutation DeleteClinician(
    $input: DeleteClinicianInput!
    $condition: ModelClinicianConditionInput
  ) {
    deleteClinician(input: $input, condition: $condition) {
      id
      firstName
      lastName
      role
      bio
      patientsAsHealthCoach {
        items {
          id
          firstName
          middleName
          lastName
          birthdate
          email
          phone
          insurance
          state
          clinicName
          healthCoachId
          healthCoach {
            id
            firstName
            lastName
            role
            bio
            imagePath
            zoomLink
            zoomInfo
            createdAt
            updatedAt
            __typename
          }
          dietitianId
          dietitian {
            id
            firstName
            lastName
            role
            bio
            imagePath
            zoomLink
            zoomInfo
            createdAt
            updatedAt
            __typename
          }
          appointments {
            nextToken
            __typename
          }
          goals {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      patientsAsDietitian {
        items {
          id
          firstName
          middleName
          lastName
          birthdate
          email
          phone
          insurance
          state
          clinicName
          healthCoachId
          healthCoach {
            id
            firstName
            lastName
            role
            bio
            imagePath
            zoomLink
            zoomInfo
            createdAt
            updatedAt
            __typename
          }
          dietitianId
          dietitian {
            id
            firstName
            lastName
            role
            bio
            imagePath
            zoomLink
            zoomInfo
            createdAt
            updatedAt
            __typename
          }
          appointments {
            nextToken
            __typename
          }
          goals {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      appointments {
        items {
          id
          patientId
          patient {
            id
            firstName
            middleName
            lastName
            birthdate
            email
            phone
            insurance
            state
            clinicName
            healthCoachId
            dietitianId
            createdAt
            updatedAt
            owner
            __typename
          }
          clinicianId
          clinician {
            id
            firstName
            lastName
            role
            bio
            imagePath
            zoomLink
            zoomInfo
            createdAt
            updatedAt
            __typename
          }
          startTime
          endTime
          appointmentType
          appointmentLocation
          phoneNumber
          zoomLink
          zoomInfo
          notes
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      imagePath
      zoomLink
      zoomInfo
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAppointment = /* GraphQL */ `
  mutation CreateAppointment(
    $input: CreateAppointmentInput!
    $condition: ModelAppointmentConditionInput
  ) {
    createAppointment(input: $input, condition: $condition) {
      id
      patientId
      patient {
        id
        firstName
        middleName
        lastName
        birthdate
        email
        phone
        insurance
        state
        clinicName
        healthCoachId
        healthCoach {
          id
          firstName
          lastName
          role
          bio
          patientsAsHealthCoach {
            nextToken
            __typename
          }
          patientsAsDietitian {
            nextToken
            __typename
          }
          appointments {
            nextToken
            __typename
          }
          imagePath
          zoomLink
          zoomInfo
          createdAt
          updatedAt
          __typename
        }
        dietitianId
        dietitian {
          id
          firstName
          lastName
          role
          bio
          patientsAsHealthCoach {
            nextToken
            __typename
          }
          patientsAsDietitian {
            nextToken
            __typename
          }
          appointments {
            nextToken
            __typename
          }
          imagePath
          zoomLink
          zoomInfo
          createdAt
          updatedAt
          __typename
        }
        appointments {
          items {
            id
            patientId
            clinicianId
            startTime
            endTime
            appointmentType
            appointmentLocation
            phoneNumber
            zoomLink
            zoomInfo
            notes
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        goals {
          items {
            id
            patientId
            q0_prevAchieved
            q1_goal
            q2_positive
            q3_improve
            q4_discuss
            startTime
            endTime
            achieved
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      clinicianId
      clinician {
        id
        firstName
        lastName
        role
        bio
        patientsAsHealthCoach {
          items {
            id
            firstName
            middleName
            lastName
            birthdate
            email
            phone
            insurance
            state
            clinicName
            healthCoachId
            dietitianId
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        patientsAsDietitian {
          items {
            id
            firstName
            middleName
            lastName
            birthdate
            email
            phone
            insurance
            state
            clinicName
            healthCoachId
            dietitianId
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        appointments {
          items {
            id
            patientId
            clinicianId
            startTime
            endTime
            appointmentType
            appointmentLocation
            phoneNumber
            zoomLink
            zoomInfo
            notes
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        imagePath
        zoomLink
        zoomInfo
        createdAt
        updatedAt
        __typename
      }
      startTime
      endTime
      appointmentType
      appointmentLocation
      phoneNumber
      zoomLink
      zoomInfo
      notes
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAppointment = /* GraphQL */ `
  mutation UpdateAppointment(
    $input: UpdateAppointmentInput!
    $condition: ModelAppointmentConditionInput
  ) {
    updateAppointment(input: $input, condition: $condition) {
      id
      patientId
      patient {
        id
        firstName
        middleName
        lastName
        birthdate
        email
        phone
        insurance
        state
        clinicName
        healthCoachId
        healthCoach {
          id
          firstName
          lastName
          role
          bio
          patientsAsHealthCoach {
            nextToken
            __typename
          }
          patientsAsDietitian {
            nextToken
            __typename
          }
          appointments {
            nextToken
            __typename
          }
          imagePath
          zoomLink
          zoomInfo
          createdAt
          updatedAt
          __typename
        }
        dietitianId
        dietitian {
          id
          firstName
          lastName
          role
          bio
          patientsAsHealthCoach {
            nextToken
            __typename
          }
          patientsAsDietitian {
            nextToken
            __typename
          }
          appointments {
            nextToken
            __typename
          }
          imagePath
          zoomLink
          zoomInfo
          createdAt
          updatedAt
          __typename
        }
        appointments {
          items {
            id
            patientId
            clinicianId
            startTime
            endTime
            appointmentType
            appointmentLocation
            phoneNumber
            zoomLink
            zoomInfo
            notes
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        goals {
          items {
            id
            patientId
            q0_prevAchieved
            q1_goal
            q2_positive
            q3_improve
            q4_discuss
            startTime
            endTime
            achieved
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      clinicianId
      clinician {
        id
        firstName
        lastName
        role
        bio
        patientsAsHealthCoach {
          items {
            id
            firstName
            middleName
            lastName
            birthdate
            email
            phone
            insurance
            state
            clinicName
            healthCoachId
            dietitianId
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        patientsAsDietitian {
          items {
            id
            firstName
            middleName
            lastName
            birthdate
            email
            phone
            insurance
            state
            clinicName
            healthCoachId
            dietitianId
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        appointments {
          items {
            id
            patientId
            clinicianId
            startTime
            endTime
            appointmentType
            appointmentLocation
            phoneNumber
            zoomLink
            zoomInfo
            notes
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        imagePath
        zoomLink
        zoomInfo
        createdAt
        updatedAt
        __typename
      }
      startTime
      endTime
      appointmentType
      appointmentLocation
      phoneNumber
      zoomLink
      zoomInfo
      notes
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAppointment = /* GraphQL */ `
  mutation DeleteAppointment(
    $input: DeleteAppointmentInput!
    $condition: ModelAppointmentConditionInput
  ) {
    deleteAppointment(input: $input, condition: $condition) {
      id
      patientId
      patient {
        id
        firstName
        middleName
        lastName
        birthdate
        email
        phone
        insurance
        state
        clinicName
        healthCoachId
        healthCoach {
          id
          firstName
          lastName
          role
          bio
          patientsAsHealthCoach {
            nextToken
            __typename
          }
          patientsAsDietitian {
            nextToken
            __typename
          }
          appointments {
            nextToken
            __typename
          }
          imagePath
          zoomLink
          zoomInfo
          createdAt
          updatedAt
          __typename
        }
        dietitianId
        dietitian {
          id
          firstName
          lastName
          role
          bio
          patientsAsHealthCoach {
            nextToken
            __typename
          }
          patientsAsDietitian {
            nextToken
            __typename
          }
          appointments {
            nextToken
            __typename
          }
          imagePath
          zoomLink
          zoomInfo
          createdAt
          updatedAt
          __typename
        }
        appointments {
          items {
            id
            patientId
            clinicianId
            startTime
            endTime
            appointmentType
            appointmentLocation
            phoneNumber
            zoomLink
            zoomInfo
            notes
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        goals {
          items {
            id
            patientId
            q0_prevAchieved
            q1_goal
            q2_positive
            q3_improve
            q4_discuss
            startTime
            endTime
            achieved
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      clinicianId
      clinician {
        id
        firstName
        lastName
        role
        bio
        patientsAsHealthCoach {
          items {
            id
            firstName
            middleName
            lastName
            birthdate
            email
            phone
            insurance
            state
            clinicName
            healthCoachId
            dietitianId
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        patientsAsDietitian {
          items {
            id
            firstName
            middleName
            lastName
            birthdate
            email
            phone
            insurance
            state
            clinicName
            healthCoachId
            dietitianId
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        appointments {
          items {
            id
            patientId
            clinicianId
            startTime
            endTime
            appointmentType
            appointmentLocation
            phoneNumber
            zoomLink
            zoomInfo
            notes
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        imagePath
        zoomLink
        zoomInfo
        createdAt
        updatedAt
        __typename
      }
      startTime
      endTime
      appointmentType
      appointmentLocation
      phoneNumber
      zoomLink
      zoomInfo
      notes
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createGoal = /* GraphQL */ `
  mutation CreateGoal(
    $input: CreateGoalInput!
    $condition: ModelGoalConditionInput
  ) {
    createGoal(input: $input, condition: $condition) {
      id
      patientId
      patient {
        id
        firstName
        middleName
        lastName
        birthdate
        email
        phone
        insurance
        state
        clinicName
        healthCoachId
        healthCoach {
          id
          firstName
          lastName
          role
          bio
          patientsAsHealthCoach {
            nextToken
            __typename
          }
          patientsAsDietitian {
            nextToken
            __typename
          }
          appointments {
            nextToken
            __typename
          }
          imagePath
          zoomLink
          zoomInfo
          createdAt
          updatedAt
          __typename
        }
        dietitianId
        dietitian {
          id
          firstName
          lastName
          role
          bio
          patientsAsHealthCoach {
            nextToken
            __typename
          }
          patientsAsDietitian {
            nextToken
            __typename
          }
          appointments {
            nextToken
            __typename
          }
          imagePath
          zoomLink
          zoomInfo
          createdAt
          updatedAt
          __typename
        }
        appointments {
          items {
            id
            patientId
            clinicianId
            startTime
            endTime
            appointmentType
            appointmentLocation
            phoneNumber
            zoomLink
            zoomInfo
            notes
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        goals {
          items {
            id
            patientId
            q0_prevAchieved
            q1_goal
            q2_positive
            q3_improve
            q4_discuss
            startTime
            endTime
            achieved
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      q0_prevAchieved
      q1_goal
      q2_positive
      q3_improve
      q4_discuss
      startTime
      endTime
      achieved
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateGoal = /* GraphQL */ `
  mutation UpdateGoal(
    $input: UpdateGoalInput!
    $condition: ModelGoalConditionInput
  ) {
    updateGoal(input: $input, condition: $condition) {
      id
      patientId
      patient {
        id
        firstName
        middleName
        lastName
        birthdate
        email
        phone
        insurance
        state
        clinicName
        healthCoachId
        healthCoach {
          id
          firstName
          lastName
          role
          bio
          patientsAsHealthCoach {
            nextToken
            __typename
          }
          patientsAsDietitian {
            nextToken
            __typename
          }
          appointments {
            nextToken
            __typename
          }
          imagePath
          zoomLink
          zoomInfo
          createdAt
          updatedAt
          __typename
        }
        dietitianId
        dietitian {
          id
          firstName
          lastName
          role
          bio
          patientsAsHealthCoach {
            nextToken
            __typename
          }
          patientsAsDietitian {
            nextToken
            __typename
          }
          appointments {
            nextToken
            __typename
          }
          imagePath
          zoomLink
          zoomInfo
          createdAt
          updatedAt
          __typename
        }
        appointments {
          items {
            id
            patientId
            clinicianId
            startTime
            endTime
            appointmentType
            appointmentLocation
            phoneNumber
            zoomLink
            zoomInfo
            notes
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        goals {
          items {
            id
            patientId
            q0_prevAchieved
            q1_goal
            q2_positive
            q3_improve
            q4_discuss
            startTime
            endTime
            achieved
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      q0_prevAchieved
      q1_goal
      q2_positive
      q3_improve
      q4_discuss
      startTime
      endTime
      achieved
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteGoal = /* GraphQL */ `
  mutation DeleteGoal(
    $input: DeleteGoalInput!
    $condition: ModelGoalConditionInput
  ) {
    deleteGoal(input: $input, condition: $condition) {
      id
      patientId
      patient {
        id
        firstName
        middleName
        lastName
        birthdate
        email
        phone
        insurance
        state
        clinicName
        healthCoachId
        healthCoach {
          id
          firstName
          lastName
          role
          bio
          patientsAsHealthCoach {
            nextToken
            __typename
          }
          patientsAsDietitian {
            nextToken
            __typename
          }
          appointments {
            nextToken
            __typename
          }
          imagePath
          zoomLink
          zoomInfo
          createdAt
          updatedAt
          __typename
        }
        dietitianId
        dietitian {
          id
          firstName
          lastName
          role
          bio
          patientsAsHealthCoach {
            nextToken
            __typename
          }
          patientsAsDietitian {
            nextToken
            __typename
          }
          appointments {
            nextToken
            __typename
          }
          imagePath
          zoomLink
          zoomInfo
          createdAt
          updatedAt
          __typename
        }
        appointments {
          items {
            id
            patientId
            clinicianId
            startTime
            endTime
            appointmentType
            appointmentLocation
            phoneNumber
            zoomLink
            zoomInfo
            notes
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        goals {
          items {
            id
            patientId
            q0_prevAchieved
            q1_goal
            q2_positive
            q3_improve
            q4_discuss
            startTime
            endTime
            achieved
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      q0_prevAchieved
      q1_goal
      q2_positive
      q3_improve
      q4_discuss
      startTime
      endTime
      achieved
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createHealthGuides = /* GraphQL */ `
  mutation CreateHealthGuides(
    $input: CreateHealthGuidesInput!
    $condition: ModelHealthGuidesConditionInput
  ) {
    createHealthGuides(input: $input, condition: $condition) {
      id
      short_title
      title
      code
      minutes_read
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateHealthGuides = /* GraphQL */ `
  mutation UpdateHealthGuides(
    $input: UpdateHealthGuidesInput!
    $condition: ModelHealthGuidesConditionInput
  ) {
    updateHealthGuides(input: $input, condition: $condition) {
      id
      short_title
      title
      code
      minutes_read
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteHealthGuides = /* GraphQL */ `
  mutation DeleteHealthGuides(
    $input: DeleteHealthGuidesInput!
    $condition: ModelHealthGuidesConditionInput
  ) {
    deleteHealthGuides(input: $input, condition: $condition) {
      id
      short_title
      title
      code
      minutes_read
      createdAt
      updatedAt
      __typename
    }
  }
`;
