import React from 'react';
import { List, ListItem, Typography } from '@mui/material';

export default function HealthCoachingGuide() {
  return (
    <div>
        <Typography variant="h4" gutterBottom color="primary.main">
        <b>What is Health Coaching?</b>
        </Typography>
        <Typography variant="h5" gutterBottom color="primary.main">
        What is a Health Coach?
        </Typography>
        <Typography paragraph>
        A health coach is a trained professional who empowers individuals to achieve their health and wellness goals by providing guidance, support, and motivation. They work as your personal health guide and cheerleader, helping you navigate your unique health journey, whether it involves weight loss, chronic condition management, or adopting a healthier lifestyle.
        </Typography>
        <Typography paragraph>
        Health coaches assist you in taking small, practical steps that lead to lasting and meaningful habits and changes. They assess your current situation, identify potential obstacles, work with you to create an action plan tailored to your goals, and help provide accountability and encouragement. Health coaches often have training in diet, lifestyle, and nutrition to guide their questions and feedback.
        </Typography>
        <Typography paragraph>
        In contrast to traditional doctor visits, where doctors prescribe treatment, health coaching makes you the active decision-maker. You set the goals, while the health coach supports you until you achieve your desired outcome. Think of a health coach as your GPS, with you in the driver's seat.
        </Typography>
        <Typography paragraph>
        For example, let’s say your doctor wants you to eat more fruits and vegetables. A health coach might start by asking questions like:
        <List>
        <ListItem>
            "On a scale of 1 to 10, with 10 being the most, how important is it for you to make this change?"
        </ListItem>
        <ListItem>
            "What feels reasonable for you?"
        </ListItem>
        <ListItem>
            "What is blocking you or what challenges might you face in following through?"
        </ListItem>
        <ListItem>
            "What’s your home life like?"
        </ListItem>
        <ListItem>
            "What plan can we put together so that you can follow through with getting more fruits and vegetables?"
        </ListItem>
        </List>
        </Typography>

        <Typography variant="h5" gutterBottom color="primary.main">
        Topics to Discuss in Health Coaching
        </Typography>
        <Typography paragraph>
        Health coaches are extremely versatile – you can discuss many aspects of life with them, including eating habits and nutrition, finances, health maintenance, life satisfaction and mindset, reduction of medications, physical activity, sleep and relaxation, stress, weight management, relationships, and spiritual practices.
        </Typography>
        <Typography paragraph>
        It's important to note that health coaches aren't medical professionals and may have limitations in providing medical advice. In such cases, they will refer you back to your doctor for medical-related questions.
        </Typography>

        <Typography variant="h5" gutterBottom color="primary.main">
        Benefits of Health Coaching
        </Typography>
        <Typography paragraph>
        Change is often difficult, even when it’s something we really want. Sometimes it even feels downright impossible. The benefit of health coaching is having someone in your corner to help you through it, and research shows it works.
        </Typography>
        <Typography paragraph>
        <b>Improving Chronic Disease Outcomes:</b> Health coaching has compelling evidence of positively impacting patients with chronic diseases like diabetes, hypertension, obesity, and heart disease. <a href='https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6125027/'>One study</a> found that people who went through three months of health coaching significantly improved their blood pressure, cholesterol, glucose (blood sugar), triglycerides, body weight, and cardiovascular fitness.
        </Typography>
        <Typography paragraph>
        <b>Education and Knowledge:</b> Health coaches can help you better understand your condition and care plan, as well as take steps to achieve it. Health coaches can help you better understand your condition and care plan, as well as take steps to achieve it. <a href='https://www.aafp.org/pubs/fpm/issues/2013/0500/p40.html#fpm20130500p40-b5'>One study</a> shows that patients with diabetes who utilize a health coach to understand their care plan had better A1C levels than those who didn’t.
        </Typography>
        <Typography paragraph>
        <b>Behavior Change:</b> It’s a common misconception that information alone promotes healthy behavior change. Information alone isn’t sufficient – setting realistic goals and creating action plans can help to target specific desired behavior changes. In a <a href='https://pubmed.ncbi.nlm.nih.gov/11772893/'>recent randomized controlled trial</a>, patients who set action plans reduced their A1C levels significantly more than those who received only education.
        </Typography>
        <Typography paragraph>
        <b>Active Participation:</b> Health coaches help patients be active participants in their health and take control of creating an action plan. In a <a href='https://pubmed.ncbi.nlm.nih.gov/12911644/'>study of 752 patients</a>, it was found that collaborative decision making, where the patient takes an active role, was associated with better adherence to medication, diet, and exercise.  
        </Typography>

        <Typography variant="h5" gutterBottom color="primary.main">
        Tools of the Trade
        </Typography>
        <Typography paragraph>
        Health coaches use a variety of strategies to help you explore your reasons for change and spark a deeper investigation into the topic of conversation. Some of these tools are described below: 
        </Typography>
        <Typography paragraph>
        <b>Motivational Interviewing,</b> where a health coach will ask open-ended questions to help you discover your own motivation and strategies for change.
        </Typography>
        <Typography paragraph>
        <b>Positive Psychology,</b> which helps you identify and build on top of what’s already working, rather than focusing on what isn’t working.
        </Typography>
        <Typography paragraph>
        <b>Stages of Change,</b> which allows health coaches to offer the appropriate type of support for you at each stage of change.
        </Typography>
        <Typography paragraph>
        <b>Habit Formation and Reversal,</b> which supports you in creating new, healthy habits and reversing unhealthy ones.
        </Typography>
        <Typography paragraph>
        <b>Accountability and Goal Setting,</b> which helps you stay on track and achieve your goals.
        </Typography>
        <Typography paragraph>
        <b>Cognitive Behavioral Therapy,</b> which helps you identify and change thoughts or beliefs that are preventing you from achieving your goals.
        </Typography>
        <Typography paragraph>
        <b>Reflective Listening and Summarizing,</b> which helps you clarify your goals, your motivations, and action plans.
        </Typography>
        
        <Typography variant="h5" gutterBottom color="primary.main">
        Tips for Working with a Health Coach
        </Typography>
        <Typography paragraph>
        <b>1. Set Clear Goals:</b> Before your first session with a health coach, take some time to reflect on your health and wellness goals. Be specific about what you want to achieve, whether it's weight loss, better nutrition, increased physical activity, or stress management. Clear goals help your health coach tailor their guidance to your needs.
        </Typography>
        <Typography paragraph>
        <b>2. Open and Honest Communication:</b> Building trust and rapport with your health coach is essential. Share your medical history, current health conditions, medications, and any concerns or challenges you face openly and honestly. The more your coach knows about you, the better they can assist you.
        </Typography>
        <Typography paragraph>
        <b>3. Collaborative Approach:</b> Health coaching is a partnership between you and your coach. Work together to create a personalized plan that aligns with your goals, preferences, and lifestyle. Your coach should respect your autonomy and involve you in decision-making.
        </Typography>
        <Typography paragraph>
        <b>4. Be Prepared for Change:</b> Understand that making sustainable changes to your health and habits may require time and effort. Be patient with yourself and trust the process. Your health coach will provide guidance and support throughout your journey.
        </Typography>
        <Typography paragraph>
        <b>5. Regular Check-Ins</b> Health coaching typically involves regular sessions with your coach. Be consistent with your appointments and actively participate in discussions. Use this time to ask questions, discuss challenges, and celebrate your successes.
        </Typography>
        <Typography paragraph>
        <b>6. Accountability:</b> Your coach will help you set actionable steps to work toward your goals. It's important to hold yourself accountable for following through on these actions. If you encounter obstacles, discuss them with your coach to find solutions together.
        </Typography>
        <Typography paragraph>
        <b>7. Practice Self-Care:</b> Remember that self-care is an integral part of wellness. Your health coach can guide you in developing self-care routines that support your physical and emotional health. Prioritize self-care to maintain balance in your life.
        </Typography>
        <Typography paragraph>
        <b>8. Celebrate Progress:</b> Celebrate your achievements, no matter how small they may seem. Recognizing your progress can boost your motivation and keep you on track toward your long-term goals.
        </Typography>
        <Typography paragraph>
        <b>9. Feedback and Adjustments:</b> Periodically assess your progress with your health coach and be open to adjusting your plan as needed. Your goals may evolve, and your coach can help you adapt your strategy accordingly.
        </Typography>
        <Typography paragraph>
        <b>10. Stay Committed:</b> Consistency is key to achieving lasting results. Stay committed to your health and wellness journey, even when faced with setbacks. Your health coach will be there to support and guide you through challenges.
        </Typography>
        <Typography paragraph>
        Working with a health coach can be a transformative experience on your path to better health. By setting clear goals, maintaining open communication, and embracing a collaborative approach, you can maximize the benefits of health coaching and achieve your desired health and wellness outcomes. Your health coach is your partner in this journey, providing guidance, motivation, and support every step of the way.
        </Typography>
    </div>
  );
}