import React, { useState, useEffect } from 'react';
import { Button, ThemeProvider, useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';
import logo from '../images/flair_logo.png';
import { theme } from '../theme';

export default function Navbar({ signout }) {
  const isExtraSmallScreen = useMediaQuery('(max-width:360px)');
  const [buttonStyle, setButtonStyle] = useState({});

  useEffect(() => {
    if (isExtraSmallScreen) {
      const screenWidth = window.innerWidth;
      const buttonWidth = screenWidth * 0.1;
      const buttonHeight = screenWidth * 0.1;

      setButtonStyle({
        width: `${buttonWidth}px`,
        height: `${buttonHeight}px`,
        fontSize: '0.65rem',
        padding: '0.5px',
        margin: '2px',
        color: 'white',
      });
    } else {
      setButtonStyle({});
    }
  }, [isExtraSmallScreen]);

  const logoStyle = isExtraSmallScreen
    ? { height: '75%', margin: '10px'}
    : { height: '100%' };

  const handleSignout = () => {
    if (signout) {
      signout();
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="header" style={{ display: 'flex', justifyContent: 'space-between', padding: '10px' }}>
        <Link to="/" className="link">
          <img style={logoStyle} src={logo} alt="Logo" />
        </Link>
        <div>
          <Link to="/" className="link">
            <Button variant="contained" color="primary" size={!isExtraSmallScreen ? 'small' : undefined} style={isExtraSmallScreen ? buttonStyle : { margin: '3px', marginTop: '8px', color: 'white' }}>Home</Button>
          </Link>
          <Link to="/profile" className="link">
            <Button variant="contained" color="primary" size={!isExtraSmallScreen ? 'small' : undefined} style={isExtraSmallScreen ? buttonStyle : { margin: '3px', marginTop: '8px', color: 'white' }}>Profile</Button>
          </Link>
          <Button variant="contained" color="primary" size={!isExtraSmallScreen ? 'small' : undefined} style={isExtraSmallScreen ? buttonStyle : { margin: '3px', marginTop: '8px', color: 'white' }} onClick={handleSignout}>Log Out</Button>
        </div>
      </div>
    </ThemeProvider>
  );
}