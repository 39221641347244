import React from 'react';
import { List, ListItem, Typography } from '@mui/material';

export default function AntiInflammatoryDiet() {
  return (
    <div>
        <Typography variant="h4" gutterBottom color="primary.main">
        <b>The Anti-Inflammatory Diet</b>
        </Typography>

        <Typography variant="h5" gutterBottom color="primary.main">
        What is the Anti-Inflammatory Diet?
        </Typography>
        <Typography paragraph>
        The anti-inflammatory diet is a type of eating plan that aims to reduce chronic inflammation in the body. An anti-inflammatory diet involves consuming foods that have anti-inflammatory properties (such as whole fruits, vegetables, and grains) while avoiding or limiting foods that are pro-inflammatory (such as processed food, alcohol, and red meat).
        </Typography>
        <Typography paragraph>
        Fresh fruits and vegetables are good sources of antioxidants, which help remove free radicals from the body. Free radicals are the natural byproducts of some bodily processes, and their buildup in the body leads to cell damage and inflammation. Additionally, <a href="https://www.medicalnewstoday.com/articles/325179.php">omega-3 fatty acids</a>, which are present in fish like salmon, may help reduce the levels of inflammatory proteins in the body. Fiber can also <a href="https://www.arthritis.org/health-wellness/healthy-living/nutrition/anti-inflammatory/anti-inflammatory-diet">have this effect</a>.
        </Typography>
        
        <Typography variant="h5" gutterBottom color="primary.main">
        The Benefits of Anti-Inflammatory Diet
        </Typography>
        <Typography paragraph>
        An anti-inflammatory diet may help patients with conditions that become worse with chronic inflammation, such as: 
          <List>
          <ListItem>Rheumatoid Arthritis</ListItem>
          <ListItem>Psoriasis</ListItem>
          <ListItem>Asthma</ListItem>
          <ListItem>Colitis</ListItem> 
          <ListItem>Inflammatory Bowel Disease (IBD), including Crohn’s Disease</ListItem>
          <ListItem>Lupus</ListItem>
          <ListItem>Hashimoto’s Thyroiditis</ListItem>
          <ListItem>Obesity</ListItem>
          <ListItem>High Blood Pressure</ListItem>
          <ListItem>Cardiovascular Disease</ListItem>
          </List>
        </Typography>

        <Typography paragraph>
        There are multiple benefits in following an anti-inflammatory diet for patients with chronic inflammatory rheumatologic conditions.
        </Typography>
        <Typography paragraph>
        <b>1. Reduced Inflammation: </b> An anti-inflammatory diet can help reduce inflammation throughout the body, which can lead to a reduction in pain, stiffness, and swelling in individuals with rheumatologic disorders. It can also improve joint function and mobility in patients with rheumatoid arthritis and similar disorders.
        </Typography>
        <Typography paragraph>
        <b>2. Lowered Disease Activity: </b> Some studies have suggested that an anti-inflammatory diet may help to reduce disease activity in certain rheumatologic disorders, such as rheumatoid arthritis.
        </Typography>
        <Typography paragraph>
        <b>3. Potential Reduction in Medication Use: </b> While an anti-inflammatory diet is not a substitute for medication, it may help to reduce the need for certain medications or lower their doses in some cases.
        </Typography>
        <Typography paragraph>
        <b>4. Better Overall Health: </b> An anti-inflammatory diet is rich in nutrients and antioxidants, which can support overall health and wellbeing. This can be particularly important for individuals with rheumatologic disorders, who may be at higher risk for other chronic health conditions.
        </Typography>

        <Typography variant="h5" gutterBottom color="primary.main">
        How to Practice an Anti-Inflammatory Diet
        </Typography>
        <Typography paragraph>
        There is no single anti-inflammatory diet. For example, both the <a href="https://www.medicalnewstoday.com/articles/149090.php">Mediterranean diet</a> and the <a href="https://www.medicalnewstoday.com/articles/254836.php">Dietary Approaches to Stop Hypertension (DASH) diet</a> include fresh fruits and vegetables, fish, whole grains, and fats that are good for the heart. Foods that are typically promoted in an anti-inflammatory diet are:
        </Typography>
        <Typography paragraph>
        <b>Fruits and vegetables: </b> These are high in antioxidants and other nutrients that can help reduce inflammation. Fruits like blueberries, blackberries, strawberries, and cherries and vegetables such as kale, spinach, and broccoli are especially high in antioxidants.
        </Typography>
        <Typography paragraph>
        <b>Whole grains: </b> These are high in fiber and can help regulate blood sugar levels, which can reduce inflammation.
        </Typography>
        <Typography paragraph>
        <b>Healthy fats: </b> Foods like fatty fish (tuna and salmon), nuts, and seeds are high in omega-3 fatty acids, which have anti-inflammatory properties.
        </Typography>
        <Typography paragraph>
        <b>Spices and herbs: </b> Many spices and herbs, such as turmeric and ginger, have anti-inflammatory properties.
        </Typography>

        <Typography paragraph>
        Foods that are typically avoided in an anti-inflammatory diet include:
        </Typography>
        <Typography paragraph>
        <b>Processed and refined foods: </b> These are often high in sugar and unhealthy fats, and can increase inflammation in the body. These include white bread, chips, cookies, candy, ice cream, and many baked goods.
        </Typography>
        <Typography paragraph>
        <b>Red meat: </b> Consuming too much red meat has been linked to increased inflammation in the body.
        </Typography>
        <Typography paragraph>
        <b>Fried and fast foods: </b> These are often high in unhealthy fats and can also increase inflammation in the body.
        </Typography>

        <Typography paragraph>
        A vegetarian or vegan diet is also a common choice for people who are looking to reduce inflammation, as these diets typically prioritize natural, whole foods. [Studies have shown](https://www.ncbi.nlm.nih.gov/pmc/articles/PMC10261540/) found that people who follow a vegan or vegetarian diet for 2 years or more typically have lower inflammatory biomarkers than those that eat meat. However, the anti-inflammatory diet doesn’t require full vegetarianism or veganism to be effective. 
        </Typography>

        <Typography variant="h5" gutterBottom color="primary.main">
        Side Effects and Risks of Anti-Inflammatory Diet
        </Typography>

        <Typography paragraph>
        With the anti-inflammatory diet, there may be some potential side effects and additional supplementation required. 
        </Typography>
        <Typography paragraph>
        <b>Contains allergens: </b> The anti-inflammatory diet emphasizes many foods that are common allergens (e.g., nuts, fish and shellfish, soy, and grains). As such, some people with allergies or food sensitivities may find it difficult to follow the anti-inflammatory diet.
        </Typography>
        <Typography paragraph>
        <b>Nutrient deficiencies: </b> While there are no common risks associated with an anti-inflammatory diet, some recommendations on the diet (for example, limiting consumption of dairy products) could lead to vitamin D and calcium deficiencies.
        </Typography>
        <Typography paragraph>
        <b>Expensive: </b> The anti-inflammatory diet can be costly because of its emphasis on high-quality fruits and vegetables. However, it’s possible to source fruits and veggies in a way that is inconvenient and cost-effective. Your health coach may have ideas on how to do so.
        </Typography>

        <Typography paragraph>
        It's important to note that an anti-inflammatory diet should be used in conjunction with other medical treatments prescribed by a healthcare professional and should not be used as a substitute for medication. No food will immediately reduce inflammation in the body when someone eats it. However, eating a balanced, broad diet of whole foods and grains is proven to reduce inflammatory markers as part of a balanced lifestyle.
        </Typography>

        <Typography paragraph>
        Certain meal plans and fasting methods may work better for some people. If you’re interested in the anti-inflammatory diet for your health, please consult your dietitian or doctor to determine how to make healthy, sustainable changes to your eating plan.
        </Typography>

        <Typography paragraph>
        The anti-inflammatory diet is one of many strategies you can use to improve chronic inflammation, and it can be a very powerful tool to reduce pain and improve your health. Eating healthy food, exercising, and sleeping are also important to maintain good health. 
        </Typography>

        <Typography paragraph>
        At the end of the day, there is no one-size-fits-all solution when it comes to nutrition – the best strategies are ones that are sustainable for you and make you feel good.
        </Typography>
    </div>
  );
}