import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
    palette: {
        primary: {
            main: '#14A8E1',
        },
    },
    typography: {
        fontFamily: [
          'Raleway',
          'sans-serif',
        ].join(','),
      },
});

export const colors = {
    tableHeadBackground: '#f6fafd',
    tableHeadText: '#1565c0'
}