import React, { useEffect, useState, useCallback } from 'react';
import { Box, Button, CircularProgress, Grid, Modal, Paper, ThemeProvider, Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import moment from 'moment';
import { theme } from '../theme';

import { Amplify } from 'aws-amplify';
import { generateClient } from 'aws-amplify/api';
import { getCurrentUser } from 'aws-amplify/auth';
import { list, downloadData } from 'aws-amplify/storage';
import config from '../amplifyconfiguration.json';

import { getPatient } from "../graphql/queries";

Amplify.configure(config);
const client = generateClient({ authMode: 'apiKey' });

const modalStyle = {
  position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, 
  bgcolor: 'background.paper', boxShadow: 24, pt: 2, px: 4, pb: 3,
};

export default function PatientProfile() {
  const isSMScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [ user, setUser ] = useState("");
  const [ patientData, setPatientData ] = useState("");

  useEffect(() => { //authUser
    async function authUser() {
      try {
        const response = await getCurrentUser();
        // console.log("Auth User:", response.userId);
        setUser(response.userId);
      } catch (err) {
        console.log(err);
      }
    }
    authUser();
  }, []);

  const getPatientData = useCallback(async () => {
    try {
      const response = await client.graphql({ query: getPatient, variables: { id: user } });
      if (response.data.getPatient) {
        setPatientData(response.data.getPatient);
      }
      // console.log("Patient Data:", response.data.getPatient);
    } catch (error) {
      console.error('Error getting patient data:', error);
    }
  }, [user, setPatientData]);

  useEffect(() => { //all other useEffects
    if (user) {
      getPatientData();
    }
  }, [user, getPatientData]);

  function PatientInfo() {
    const isSMScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const birthdate = new Date(patientData.birthdate);

    return (
      <div>
        <Typography variant={isSMScreen ? 'h6' : 'h5'} style={{margin: '20px', marginTop: '25px', marginBottom: '0px'}}> <b>Your Information</b> </Typography>
        { patientData ? (
          <Paper elevation={2} style={{ margin: '20px', padding: '20px', minHeight: '75px', marginTop: '10px' }}>
          <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={12}>
            <Typography variant={isSMScreen ? 'body2' : 'body1'} style={{ textAlign: 'left', marginTop: '2px' }}> <b>Birth Date:</b> {moment(birthdate).local().format('MMM DD, YYYY')} </Typography>
            <Typography variant={isSMScreen ? 'body2' : 'body1'} style={{ textAlign: 'left', marginTop: '2px' }}> <b>Email:</b> {patientData.email} </Typography>
            <Typography variant={isSMScreen ? 'body2' : 'body1'} style={{ textAlign: 'left', marginTop: '2px' }}> <b>Phone:</b> {patientData.phone} </Typography>
            <Typography variant={isSMScreen ? 'body2' : 'body1'} style={{ textAlign: 'left', marginTop: '2px' }}> <b>Clinic Name:</b> {patientData.clinicName} </Typography>
          </Grid>
          </Grid>
          </Paper>
        ) : (
          <Paper elevation={2} style={{ margin: '20px', padding: '10px', textAlign: 'center' }}>
            <Typography variant="body1">We're still collecting your information. Check back soon for updates!</Typography>
          </Paper>
        )}
      </div>
    )
  }

  function CareTeam() {
    const isSMScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const boxSize = '80px';
    const [ clinicianImages, setClinicianImages ] = useState([]);
  
    const [coachOpen, setCoachOpen] = useState(false);
    const handleCoachOpen = () => setCoachOpen(true);
    const handleCoachClose = () => setCoachOpen(false);
  
    const [dietitianOpen, setDietitianOpen] = useState(false);
    const handleDietitianOpen = () => setDietitianOpen(true);
    const handleDietitianClose = () => setDietitianOpen(false);

    useEffect(() => {
      const fetchImages = async () => {
        const imagePaths = [
          { role: 'dietitian', path: patientData.dietitian?.imagePath },
          { role: 'healthCoach', path: patientData.healthCoach?.imagePath }
        ];
        for (const { role, path } of imagePaths) {
          if (path && !clinicianImages[path]) {
            try {
              const { body } = await downloadData({
                key: `${path}.jpeg`,
                options: { accessLevel: 'guest' }
              }).result;
              const blob = new Blob([body], { type: 'image/jpeg' });
              const url = URL.createObjectURL(blob);
              setClinicianImages(prevUrls => ({ ...prevUrls, [path]: url }));
            } catch (error) {
              try {
                const { body } = await downloadData({
                  key: `empty_profile.jpeg`,
                  options: { accessLevel: 'guest' }
                }).result;
                const blob = new Blob([body], { type: 'image/jpeg' });
                const url = URL.createObjectURL(blob);
                setClinicianImages(prevUrls => ({ ...prevUrls, [path]: url }));
              } catch (error) {
                console.error('Error downloading default image:', error);
              }
            }
          }
        }
      };
    
      if (patientData.dietitian || patientData.healthCoach) {
        fetchImages();
      }
    }, [patientData, clinicianImages, setClinicianImages]);
    

    return (
      <div>
        <Typography variant={isSMScreen ? 'h6' : 'h5'} style={{margin: '20px', marginTop: '25px', marginBottom: '0px'}}> <b>Your Care Team</b> </Typography>
        { patientData.dietitianId ? (
          <Paper elevation={2} style={{ margin: '20px', padding: '20px', minHeight: '75px', marginTop: '10px' }}>
          <Grid container spacing={2} alignItems="center">
          <Grid item xs={6} md={8}>
            <Typography variant={isSMScreen ? 'body1' : 'h6'} style={{ textAlign: 'left', margin: '0' }}> <b>{patientData.dietitian.firstName} {patientData.dietitian.lastName}</b> </Typography>
            <Typography variant="body2" color="primary.main" style={{ textAlign: 'left', margin: '0', paddingBottom: '5px' }}> <b>Dietitian</b> </Typography>
            <Button variant="contained" color="primary" size="small" style={{color:'white'}} onClick={handleDietitianOpen} > Bio </Button>
            <Modal open={dietitianOpen} onClose={handleDietitianClose}>
              <Box sx={modalStyle}>
              <Grid container spacing={2}>
                <Grid item xs={8} md={8}>
                    <Typography variant="h6" color="primary.main" component="h2"> <b>{patientData.dietitian.firstName} {patientData.dietitian.lastName}</b> </Typography>
                </Grid>
                <Grid item xs={4} md={4}>
                  <Box sx={{ position: 'relative', display: 'flex', width: boxSize, height: boxSize, 
                            borderRadius: '50%', overflow: 'hidden', alignItems: 'center', justifyContent: 'center' }}>
                    <img src={clinicianImages[patientData.dietitian.imagePath]} alt="Clinician" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                  </Box>
                </Grid>
              </Grid>
              <Typography sx={{ mt: 2 }}> Bonnie is a Registered Dietitian with over 4 years of experience across a variety of conditions, including diabetes, heart disease, and kidney disease. In the past, Bonnie was a dietitian at the University of Chicago, a nutrition consultant at a wellness program, and co-managed a hospital-based food pantry. Bonnie graduated from California State University in Long Beach, holds a Master of Public Health with a focus in nutrition, and is a certified Women's Fitness Specialist. Bonnie is based in Indiana and in her free time, she loves sheltering and rescuing dogs, bird watching, podcasts, cycling, outdoor concerts, and baking. </Typography>
              </Box>
            </Modal>
          </Grid>
          <Grid item xs={6} md={4}>
            <Box sx={{ position: 'relative', display: 'flex', width: boxSize, height: boxSize, 
                      borderRadius: '50%', overflow: 'hidden', alignItems: 'center', justifyContent: 'center' }}>
              <img src={clinicianImages[patientData.dietitian.imagePath]} alt="Clinician" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
            </Box>
          </Grid>
          </Grid>
          </Paper>
        ) : (
          <Paper elevation={2} style={{ margin: '20px', padding: '10px', textAlign: 'center' }}>
            <Typography variant="body1">We're still assigning you a dietitian. Check back soon for updates!</Typography>
          </Paper>
        )}
        { patientData.healthCoachId ? (
          <Paper elevation={2} style={{ margin: '20px', padding: '20px', minHeight: '75px', marginTop: '10px' }}>
          <Grid container spacing={2} alignItems="center">
          <Grid item xs={6} md={8}>
            <Typography variant={isSMScreen ? 'body1' : 'h6'} style={{ textAlign: 'left', margin: '0' }}> <b> {patientData.healthCoach.firstName} {patientData.healthCoach.lastName} </b> </Typography>
            <Typography variant="body2" color="primary.main" style={{ textAlign: 'left', margin: '0', paddingBottom: '5px' }}> <b>Health Coach</b> </Typography>
            <Button variant="contained" color="primary" size="small" style={{color:'white'}} onClick={handleCoachOpen} > Bio </Button>
            <Modal open={coachOpen} onClose={handleCoachClose}>
              <Box sx={modalStyle}>
              <Grid container spacing={2}>
                <Grid item xs={8} md={8}>
                    <Typography variant="h6" color="primary.main" component="h2"> <b>{patientData.healthCoach.firstName} {patientData.healthCoach.lastName}</b> </Typography>
                </Grid>
                <Grid item xs={4} md={4}>
                  <Box sx={{ position: 'relative', display: 'flex', width: boxSize, height: boxSize, 
                            borderRadius: '50%', overflow: 'hidden', alignItems: 'center', justifyContent: 'center' }}>
                      <img src={clinicianImages[patientData.healthCoach.imagePath]} alt="Clinician" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                  </Box>
                </Grid>
              </Grid>
              <Typography sx={{ mt: 2 }}> {patientData.healthCoach.bio} </Typography>
              </Box>
            </Modal>
          </Grid>
          <Grid item xs={6} md={4}>
            <Box sx={{ position: 'relative', display: 'flex', width: boxSize, height: boxSize, 
                      borderRadius: '50%', overflow: 'hidden', alignItems: 'center', justifyContent: 'center' }}>
              <img src={clinicianImages[patientData.healthCoach.imagePath]} alt="Clinician" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
            </Box>
          </Grid>
          </Grid>
          </Paper>
        ) : (
          <Paper elevation={2} style={{ margin: '20px', padding: '10px', textAlign: 'center' }}>
            <Typography variant="body1">We're still assigning you a health coach. Check back soon for updates!</Typography>
          </Paper>
        )}
      </div>
    )
  }
  
  return (
    <ThemeProvider theme={theme}>
      <Grid container>
        <div className="App"> 
          <Grid item xs={12}> 
            {patientData ? (
              <>
              <Typography variant={isSMScreen ? 'h5' : 'h4'} color="primary.main" style={{margin: '20px', marginBottom: '0px'}}> <b>{patientData.firstName} {patientData.middleName} {patientData.lastName} – Profile </b> </Typography>
              <PatientInfo />
              <CareTeam />
              </>
            ) : (
              <CircularProgress />
            )}
          </Grid>
          <Grid item xs={12}>
          <Typography variant="body2" style={{ textAlign: 'left', paddingBottom: '20px' }}> 
            Powered by <a href="https://www.flairhealth.com" target="_blank" rel="noopener noreferrer"> Flair Health </a>
          </Typography>
          </Grid>
        </div>
      </Grid>
    </ThemeProvider>
  );
}